import React, { createRef, useRef, useState } from 'react';
import * as S from './style';
import { 
    Col, 
    Row, 
    Form, 
} from 'react-bootstrap';
import {
    useCity,
    useOccurrences,
    useServices,
    useSources,
    useUf
} from '../../Hooks/index';
import {
    AREA,
    BREED,
    GENRE,
    SPECIAL_LOCATION,
    STATE_SERVICE
} from '../../Utils/index';
import { blueDownArrow, logoWhater } from '../../Assets/index';
import {
    CardGraficItem,
    PieGrafic,
    YearGrafic
} from '../../Components/index';    
import ReactToPrint from 'react-to-print'
import { orangeThunder } from '../../Assets/index';

const ProduzaSeusDados: React.FC = () => {
    const [ idUf, setIdUf ] = useState<number>(0);
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ service, setService ] = useState('')
    const [ font, setFont ] = useState('')
    const [ status, setStatus ] = useState('');
    const [ special, setSpecial ] = useState('');
    const [ typeSpecial, setTypeSpecial ] = useState('');
    const [ initialDate, setInitialDate ] = useState('');
    const [ finalDate, setFinalDate ] = useState('');
    const [ area, setArea ] = useState('');
    const [ genre, setGenre ] = useState('');
    const [ breed, setBreed ] = useState('')
    
    const { data: stateList } = useUf();
    const { data: cityList } = useCity(state);
    const { data: services } = useServices();
    const { data: sources } = useSources();
    const { data: occurrences } = useOccurrences(
        state,
        city,
        service,
        font,
        status,
        special,
        typeSpecial,
        initialDate,
        finalDate,
        area,
        genre,
        breed
    );

    const ref = createRef<any>()

    const cardGraficItem = [
        {label: 'energia', value: Math.floor(Math.random() * 100)},
        {label: 'energia', value: Math.floor(Math.random() * 100)},
        {label: 'energia', value: Math.floor(Math.random() * 100)},
        {label: 'energia', value: Math.floor(Math.random() * 100)},
        {label: 'energia', value: Math.floor(Math.random() * 100)},
        {label: 'energia', value: Math.floor(Math.random() * 100)},
        {label: 'energia', value: Math.floor(Math.random() * 100)},
    ];

    const cardGraficItem2 = [
        {label: 'água', value: Math.floor(Math.random() * 100)},
        {label: 'água', value: Math.floor(Math.random() * 100)},
        {label: 'água', value: Math.floor(Math.random() * 100)},
        {label: 'água', value: Math.floor(Math.random() * 100)},
        {label: 'água', value: Math.floor(Math.random() * 100)},
        {label: 'água', value: Math.floor(Math.random() * 100)},
        {label: 'água', value: Math.floor(Math.random() * 100)},
    ];

    return (
        <S.Container>
            <S.Card>
                <header>
                    <h1>Filtros</h1>
                </header>
                <div>
                    <Row className="mb-3">
                        <Col xs={12} sm={6} lg={3} className="mb-2">
                            <label htmlFor="state">Estado</label>
                            <Form.Select
                                aria-label='Selecione uma opção'
                                onChange={(e: any) => {
                                    setState(e?.target?.value)
                                }}
                            >
                                <option value={''}>Todos</option>
                                {stateList?.map((id: any) => (
                                    <option value={id.sigla}>
                                        {id.nome}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} lg={3} className="mb-2">
                            <label htmlFor="municipio">Município</label>
                            <Form.Select
                                aria-label='Selecione uma opção'
                                onChange={(e: any) => {
                                    setCity(e?.target?.value)
                                }}
                            >
                                <option value={''}>Todos</option>
                                {cityList?.map((id: any) => (
                                    <option value={id.nome}>
                                        {id.nome}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} lg={3} className="mb-2">
                            <label htmlFor="service">Serviço indisponivel</label>
                            <Form.Select
                                aria-label='Selecione uma opção'
                                onChange={(e: any) => {
                                    setService(e?.target?.value)
                                }}
                            >
                            <option value={''}>Todos</option>
                                {services?.map((id: any, index: number) => (
                                    <option value={id.id} key={index}>{id.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} lg={3} className="mb-2">
                            <label htmlFor="source">Fornecedora do serviço</label>
                            <Form.Select
                                aria-label='Selecione uma opção'
                                onChange={(e: any) => {
                                    setFont(e?.target?.value)
                                }}
                            >
                            <option value={''}>Todos</option>
                                {sources?.map((id: any, index: number) => {
                                    if(id?.service?.id === service){
                                        return (
                                            <option key={index} value={id?.id}>{id?.name}</option>
                                        )
                                    }
                                })}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12} sm={6} xl={2} className="mb-2">
                            <label htmlFor="status">Status do serviço</label>
                            <Form.Select
                                aria-label='Selecione uma opção'
                                onChange={(e: any) => setStatus(e?.target?.value)}
                            >
                            <option value={''}>Todos</option>
                                {STATE_SERVICE.map((id) => (
                                    <option value={id.value}>{id.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} xl={4} className="mb-2">
                            <Row>
                                <label htmlFor="special_plate">Ocorrência em localização especial?</label>
                            </Row>
                            <Row className="d-flex align-items-center">
                                <Col xs={3} sm={3} className=" d-flex align-items-center">
                                    <Form.Check
                                        inline
                                        name="special_plate"
                                        type="radio"
                                        value="Yes"
                                        onChange={(e: any) => setSpecial(e?.target?.value)}
                                    />
                                    <S.Options>Sim</S.Options>
                                </Col>
                                <Col xs={3} sm={3} className=" d-flex align-items-center">
                                    <Form.Check
                                        inline
                                        name="special_plate"
                                        type="radio"
                                        value="No"
                                        onChange={(e: any) => setSpecial(e?.target?.value)}
                                    />
                                    <S.Options>Não</S.Options>
                                </Col>
                                <Col xs={6} sm={6} className=" d-flex align-items-center">
                                    <Form.Check
                                        inline
                                        name="special_plate"
                                        type="radio"
                                        value={undefined}
                                        onChange={(e: any) => setSpecial(e?.target?.value)}
                                        defaultChecked={true}
                                    />
                                    <S.Options>Ambas</S.Options>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={6} xl={2}>
                            <label htmlFor="status">Localicação especial</label>
                            <Form.Select
                                aria-label='Selecione uma opção'
                                onChange={(e: any) => setTypeSpecial(e?.target?.value)}
                            >
                            <option value={''}>Todos</option>
                                {SPECIAL_LOCATION.map((id) => (
                                    <option value={id.value}>{id.label}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} xl={2} className="mb-2">
                            <label>De</label>
                            <Form.Control 
                                type="date"
                                onChange={(e: any) => setInitialDate(e?.target?.value)}
                            />
                        </Col>
                        <Col xs={12} sm={6} xl={2} className="mb-2">
                            <label>Até</label>
                            <Form.Control 
                                type="date"
                                onChange={(e: any) => setFinalDate(e?.target?.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6} lg={3} className="mb-2">
                            <label htmlFor="">Área afetada</label>
                            <Form.Select
                                aria-label='Selecione uma opção'
                                onChange={(e: any) => setArea(e?.target?.value)}
                            >
                            <option value={''}>Todos</option>
                                {AREA.map((id) => (
                                    <option value={id.value}>{id.label}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} lg={3} className="mb-2">
                            <label htmlFor="">Gênero</label>
                            <Form.Select
                                aria-label='Selecione uma opção'
                                onChange={(e: any) => setGenre(e?.target?.value)}
                            >
                            <option value={''}>Todos</option>
                                {GENRE.map((id) => (
                                    <option value={id.value}>{id.label}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={12} lg={3} className="mb-2">
                            <label htmlFor="">Raça</label>
                            <Form.Select
                                aria-label='Selecione uma opção'
                                onChange={(e: any) => setBreed(e?.target?.value)}
                            >
                            <option value={''}>Todos</option>
                                {BREED.map((id) => (
                                    <option value={id.value}>{id.label}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                </div>
            </S.Card>
            <S.ResultSearch>
                <header>
                    <h2>Relatório</h2>
                    <ReactToPrint 
                        trigger={()=>(<S.Download>
                            Baixar relatório
                            <img src={blueDownArrow} alt="" />
                        </S.Download>)}
                        content={()=>ref.current}
                    />
                </header> 

                <S.DivRender id="render-pdf" ref={ref}>
                    <Row className="mt-5 d-flex justify-content-between">
                        {occurrences?.line_charts?.map((id: any, index: number) => (
                            <Col xs={12} sm={5} lg={6} key={index}>
                                <CardGraficItem
                                    title={id?.name}
                                    value={id?.value}
                                    icon={id?.name === "Água" ? logoWhater : orangeThunder}
                                    id={id?.name === 'Água' ? 'água' : 'energia'}
                                    heightGrafic={180}
                                    list={id?.charts}
                                    backgroundColor={id?.name === "Água" ? "#1773E2" : "#FF954E"}
                                />
                            </Col>
                        ))}
                    </Row>
                    <Row className="mt-5 ">
                        <Col style={{background: '#FFFFFF'}}>
                            <S.ContainerCard>
                                <YearGrafic 
                                    title='Ocorrências'
                                    number={occurrences?.total || 0}
                                    data={occurrences?.annual_charts}
                                    width= "100%"
                                    height='auto'                    
                                    heightGrafic={300}
                                    services={occurrences?.line_charts}
                                />
                            </S.ContainerCard>
                        </Col>
                    </Row>
                    {/* <Row className="mt-5 d-flex justify-content-between">
                        <Col lg={6} >
                            <S.ContainerCard>
                                <PieGrafic 
                                    data={occurrences?.gender_charts}
                                    type='genero'
                                />
                            </S.ContainerCard>
                        </Col>
                        <Col lg={6} className="mt-5 m-lg-0">
                            <S.ContainerCard>
                                <PieGrafic 
                                    data={occurrences?.breed_charts}
                                    type='raça'
                                />
                            </S.ContainerCard>
                        </Col>
                    </Row> */}
                </S.DivRender> 
            </S.ResultSearch>
        </S.Container>
    );
};

export default ProduzaSeusDados;