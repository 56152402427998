import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { api } from "../Functions";
import { IFormContato } from "../Interfaces/IFormContato";

const getFormContato = async <T>():Promise<IFormContato> => {
    const resp = await api.get<IFormContato>(`/contact-forms`);

    return resp.data;
};

export const useFormContato = <T>():UseQueryResult<IFormContato> => {
    return useQuery(['form-contato'], () => getFormContato());
};