import { ibgeApi } from "../Functions";
import { ICity } from '../Interfaces/ICity';
import { UseQueryResult, useQuery } from "@tanstack/react-query";

export const getCity = async (ufId: any):Promise<ICity []> => {
    const {data: resp} = await ibgeApi.get<ICity []>(`/v1/localidades/estados/${ufId}/municipios?orderBy=nome`)

    return resp
}

export const useCity = (ufId: any):UseQueryResult<ICity []> => {
    return useQuery(["city", ufId], () => getCity(ufId))
}