import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { api } from "../Functions";
import { IFooter } from "../Interfaces/IFooter";

const getFooter = async <T>():Promise<IFooter> => {
    const resp = await api.get<IFooter>(`/footers?populate=*,social_medias.social_media`);

    return resp.data;
};

export const useFooter = <T>():UseQueryResult<IFooter> => {
    return useQuery(['footers'], () => getFooter());
};