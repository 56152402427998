import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { sosApi } from "../Functions";
import { IOccurrences } from '../Interfaces/IOccurrences';

const getOccurrences = async <T>(
    state: string,
    city: string,
    service: string,
    font: string,
    status: string,
    special: string,
    locale: string,
    initialDate: string,
    finalDate: string,
    area: string,
    genre: string,
    breed: string
):Promise<IOccurrences> => {

    let params = new URLSearchParams();
    if(state !== '') params.append("state", state)
    if(city !== '') params.append("city", city)
    if(service !== '') params.append("service", service)
    if(font !== '') params.append("source", font)
    if(status !== '') params.append("status", status)
    if(special !== '') params.append("special_place", special)
    if(locale !== '') params.append("type_place", locale)
    if(initialDate !== '') params.append("initialdate", initialDate)
    if(finalDate !== '') params.append("finaldate", finalDate)
    if(area !== '') params.append("area", area)
    if(genre !== '') params.append("genre", genre)
    if(breed !== '') params.append("breed", breed)


    const resp = await sosApi.get<IOccurrences>(`/dashboard/occurrences`, {
        params: params
    });

    return resp.data;
};

export const useOccurrences = <T>(
    state: string,
    city: string,
    service: string,
    font: string,
    status: string,
    special: string,
    locale: string,
    initialDate: string,
    finalDate: string,
    area: string,
    genre: string,
    breed: string
):UseQueryResult<IOccurrences> => {
    return useQuery([
        'occurrences',
        state,
        city,
        service,
        font,
        status,
        special,
        locale,
        initialDate,
        finalDate,
        area,
        genre,
        breed
    ], () => getOccurrences(state, city, service, font, status, special, locale, initialDate, finalDate, area, genre, breed));
};