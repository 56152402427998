import React from 'react';
import * as S from './style';
import { useAbout, usePartners } from '../../Hooks';
import {
    Col,
    Row,
    Image
} from 'react-bootstrap';
import renderHTML from 'react-render-html';
import { Helmet } from "react-helmet";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const Sobre: React.FC = () => {
    const { data: about, isLoading } = useAbout();
    const { data: partners } = usePartners();

    return (
        <>
            <Helmet 
                title="Sos - Água e Luz | Sobre"
            />
            {!isLoading ? (
                <>
                    {about?.data?.map((id: any, index: number) => {
                        if(index === 0){
                            return (
                                <S.BackgroundColor>
                                    <div>
                                        <Row className="d-flex flex-sm-column flex-md-row flex-lg-row">
                                            <Col xs={12} md={12} lg={6}>
                                                <Image fluid src={id?.attributes?.image?.data?.attributes?.url} alt="" width={960}/>
                                            </Col>
                                            <Col xs={12} md={12} lg={4} className="m-3 mt-lg-5">
                                                <Row className="mb-3 mt-lg-5">
                                                    <S.Title>{id?.attributes?.title}</S.Title>
                                                </Row>
                                                {id?.attributes?.block?.map((block: any) => (
                                                    <S.Paragraph>{renderHTML(block?.content)}</S.Paragraph>
                                                ))}
                                            </Col>
                                        </Row>
                                    </div>
                                </S.BackgroundColor>
                            )
                        }
        
                        if(index === 1){
                            return (
                                <>
                                    <S.DescriptionIntro>
                                        <div>
                                            <Row className="mt-5 mb-5 mx-4 d-flex flex-sm-column flex-md-row flex-lg-row" >
                                                {id?.attributes?.block?.map((block: any) => (
                                                    <Col xs={12} md={12} lg={6}>    
                                                        <S.Paragraph>{renderHTML(block?.content)}</S.Paragraph>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    </S.DescriptionIntro>
                                    <S.Partners>
                                        {partners?.data.map((id: any) => {
                                            return (
                                                <>
                                                    <S.ContainerRigth>
                                                        {id?.attributes?.partners1?.map((item: any) => (
                                                            <div>
                                                                <img src={item?.image?.data?.attributes?.url} alt="" />
                                                            </div>        
            
                                                        ))}
                                                    </S.ContainerRigth>
                                                    <S.ContainerLeft>
                                                        {id?.attributes?.partners2?.map((item: any) => (
                                                            <div>
                                                                <img src={item?.image?.data?.attributes?.url} alt="" />
                                                            </div>        
            
                                                        ))}
                                                    </S.ContainerLeft>
                                                    <S.ContainerRigth>
                                                        {id?.attributes?.partners3?.map((item: any) => (
                                                            <div>
                                                                <img src={item?.image?.data?.attributes?.url} alt="" />
                                                            </div>        
            
                                                        ))}
                                                    </S.ContainerRigth>
                                                </>
                                            )
                                        })}
                                        
                                        
                                    </S.Partners>
                                </>
                            )
                        }
        
                        if(index === 2){
                            return (
                                <S.FirstContent>
                                    <div>
                                        <Row className="m-2 m-lg-4 d-flex flex-sm-column flex-md-row flex-lg-row mt-xl-5">
                                            <Col xs={12} md={6} lg={6}>
                                                <S.Description>nossa história</S.Description>
                                                <S.SubTitle>{id?.attributes?.title}</S.SubTitle>
                                                {id?.attributes?.block?.map((block: any) => (
                                                    <Col xs={12} md={12} lg={10}>    
                                                        <S.Paragraph>{renderHTML(block?.content)}</S.Paragraph>
                                                    </Col>
                                                ))}
                                            </Col>
                                            <Col xs={12} md={6} lg={6} className="mb-5">
                                                <Image fluid src={id?.attributes?.image?.data?.attributes?.url} alt="" />
                                            </Col>
                                        </Row>
                                    </div>
                                </S.FirstContent>
                            )
                        }
        
                        if(index === 3){
                            return (
                                <S.SeccondContent>
                                    <div>
                                        <Row  className="m-2 m-lg-4 d-flex flex-sm-column flex-md-row flex-lg-row">
                                            <Col xs={12} md={6} lg={6} className="mb-5">
                                                <Image fluid src={id?.attributes?.image?.data?.attributes?.url} alt="" />
                                            </Col>
                                            <Col xs={12} md={6} lg={6}>
                                                {id?.attributes?.block?.map((block: any) => (
                                                    <Col xs={12} md={12} lg={10}>    
                                                        <S.Paragraph>{renderHTML(block?.content)}</S.Paragraph>
                                                    </Col>
                                                ))}
                                            </Col>
                                        </Row>
                                    </div>
                                </S.SeccondContent>
                            )
                        }
                    })}
                </>
            ) : (
                <Row>
                    <SkeletonTheme baseColor="#F1F1F1" highlightColor="#F7F7F7">
                        <Row className="d-flex justify-content-between mb-5">
                            <Col>
                                <Skeleton height={400} />
                            </Col>
                            <Col>
                                <Skeleton height={30} count={1} width={130}/>
                                <Skeleton height={15} count={15}/>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-between mb-5">
                            <Col>
                                <Skeleton height={15} count={5} />
                            </Col>
                            <Col>
                                <Skeleton height={15} count={5}/>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-between mb-5">
                            <Col>
                                <Skeleton height={400} />
                            </Col>
                            <Col>
                                <Skeleton height={400} />
                            </Col>
                            <Col>
                                <Skeleton height={400} />
                            </Col>
                            <Col>
                                <Skeleton height={400} />
                            </Col>
                        </Row>
                    </SkeletonTheme>
                </Row>
            )}

        </>
    );
};

export default Sobre;