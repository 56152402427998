import React from 'react';
import { Col, Row } from 'react-bootstrap';
import * as S from './style';
import { useNavigate } from 'react-router-dom';

interface IProps {
    open: string,
    date: string,
    title: string,
    desciption: string,
    icon: string,
    thumbnail: string,
    index: number,
    type: string
}

const Card: React.FC <IProps> = ({
    open,
    date,
    title,
    desciption,
    icon,
    thumbnail,
    index,
    type
}) => {
    const navigate = useNavigate()
    return (
            <S.Card
                body={false}
                onClick={() => {
                    if(type !== 'reports'){
                        navigate(open)
                    }
                }}
            >
                <S.Date>{date}</S.Date>
                <S.Thumbnail src={thumbnail} alt="" />
                <S.Content>
                    <div>
                        <S.Author src={icon} alt="" />
                    </div>
                    <S.Title>{title}</S.Title>
                    <S.Descriprion>{desciption}</S.Descriprion>
                </S.Content>
                <S.Footer>
                    {type === 'reports' ?
                        <S.Download href={open} target="_blank">
                            Baixar relatório
                        </S.Download>

                        :
                        null
                    }
                </S.Footer>
            </S.Card>
    );
};

export default Card;