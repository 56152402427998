import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1920px;
    margin: 0 auto;
    > div {
        display: flex;
        flex-direction: column;
        margin: 20px 10%;

        > h1 {
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 60px;
            color: #2C3941;
            margin-bottom: 20px;
        }

        > h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 39px;
            color: #2C3941;
            margin-bottom: 20px;
            margin-top: 15px;
        }

        > p {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            color: #2C3941;
            margin-bottom: 10px;
        }

        > a {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 5px;
        }

        > span {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            color: #2C3941;
        }
    }
`;