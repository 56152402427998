import styled from "styled-components";

export const BackgroundColor = styled.div`
    background: #F8F8F8;
    width: 100%;
    > div {
        margin: 0 auto;
        max-width: 1920px;

    }
`;

export const DescriptionIntro = styled.div`
    width: 100%;
    > div {
        margin: 0 auto;
        max-width: 1920px;

    }
`;

export const Partners = styled.div`
    width: 100%;

    > div {
        margin: 0 auto;
        max-width: 1920px;
    }
`;

export const FirstContent = styled.div`
    width: 100%;
    > div {
        max-width: 1920px;
        margin: 0 auto;
    }
`;

export const SeccondContent = styled.div`
    width: 100%;
    > div {
        max-width: 1920px;
        margin: 0 auto;
    }
`;

export const ContainerRigth = styled.div`
    overflow: hidden;
    display: flex;
    height: 100px;
    width: 100%;
    margin: 0 auto 10px;

    > div {
        height: 100px;
        min-width: 300px;
        margin-left: 40px;
        animation: slideR 30s linear infinite;
    }

    @keyframes slideR {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(-1920px, 0, 0);
        }
    }
`;

export const ContainerLeft = styled.div`
    overflow: hidden;
    display: flex;
    height: 100px;
    width: 100%;
    margin: 0 auto 10px;

    > div {
        height: 100px;
        min-width: 300px;
        margin-left: 40px;
        animation: slideL 30s linear infinite;
    }

    @keyframes slideL {
        100% {
            transform: translate3d(0,0, 0);
        }
        0% {
            transform: translate3d(-1920px , 0, 0);
        }
    }
`;

export const Paragraph = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #2C3941;

    @media (max-width: 991px){
        font-size: 18px;
    }
`;

export const Title = styled.h1`
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #2C3941;
`;

export const SubTitle = styled.h2`
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #2C3941;
`;

export const Description = styled.p`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    text-transform: uppercase;
    color: #1773E2;
`;