import { IReports } from './../Interfaces/IReports';
import { queryClient } from './../Functions/query/index';

import { api } from "../Functions";
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getReports = async <T>(
    page: number
):Promise<IReports []> => {
    let atualPage = page.toString()
    const resp = await api.get<IReports []>(`/reports?populate=*&sort=publishedAt%3Adesc&pagination%5BpageSize%5D=15&pagination%5Bpage%5D=${atualPage}`);

    return resp.data;
};

export const useReports = <T>(page: number):UseQueryResult<IReports> => {
    return useQuery(['reports', page], () => getReports(page), {
        staleTime: 30 * 1000,
    });
};