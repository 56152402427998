import styled from "styled-components";
import {
    Container as ContainerBt
} from 'react-bootstrap';

export const Content = styled.div`
    background: #F8F8F8;
    width: 100%;
    > div {
        max-width: 1920px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            color: #1E3040;
        }
    }
`;

export const ImageHeader = styled.img`
    width: 100%;
    height: 428px;
    object-fit: cover;

    @media (max-width: 576px){
        height: 200px;
    }

    @media (min-width: 577px) and (max-width: 767px) {  
        height: 200px;
    }

    @media (min-width: 768px) and (max-width: 991px) {  
        height: 250px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {   

    }

    @media (min-width: 1200px) and (max-width: 1399px) {  

    }

    @media (min-width: 1400px){        

    }
`;

export const Container = styled.div`
    background: #FFFFFF;
    border-radius: 20px;

    @media (max-width: 576px){
        margin: -80px 20px 0;
        padding: 32px 16px;

        .img-icon {
            width: 50px;
        }
    }

    @media (min-width: 577px) and (max-width: 767px) {  
        margin: -80px 20px 0;
        padding: 32px 16px;

        .img-icon {
            width: 50px;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {   
        margin: -80px 20px 0;
        padding: 32px 16px;

        .img-icon {
            width: 50px;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {   
        margin: -80px 20px 0;
        padding: 32px 16px;

        .img-icon {
            width: 50px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1399px) { 
        margin: -80px 20px 0;
        padding: 32px 16px;

        .img-icon {
            width: 50px;
        }

    }

    @media (min-width: 1400px){        
        margin: -260px 160px 0;
        padding: 80px;
    }
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #1E3040;
`;

export const Author = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 48px;
    color: #0A3466;
    margin: 0 !important;
`;

export const Date = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 48px;
    color: #2C3941;
    opacity: 0.5;
    margin: 0 !important;
`;

export const ContentPost = styled.div`
    img {
        max-width: 80% !important;
    }

    @media (max-width: 768px){  
        display: flex;
        flex-direction: column;

        > p {
            display: flex;
            flex-direction: column;
        }
    }
`;