import React from 'react';
import {
    Routes,
    Route
} from 'react-router-dom';
import {
    Blog,
    Dados,
    Home,
    PerguntasFrequentes,
    Sobre,
    PoliticaDePrivacidade,
    PostBlog,
    FormContato
} from '../Screens/index';

const AuthRoutes: React.FC = () => {

    const list = [
        { path: '/', element: <Home/> },
        { path: '/sobre', element: <Sobre/> },
        { path: '/noticias', element: <Blog/> },
        { path: '/noticias/:slug', element: <PostBlog/> },
        { path: '/dados', element: <Dados/> },
        { path: '/perguntas-frequentes', element: <PerguntasFrequentes/> },
        { path: '/politica-de-privacidade', element: <PoliticaDePrivacidade/> },
        { path: '/faleconosco', element: <FormContato/> }
    ];

    return (
        <Routes>
            {list.map((id: any) => {
                return (
                    <Route path={id.path} element={id.element}/>
                )
            })}
        </Routes>
    );
};

export default AuthRoutes;