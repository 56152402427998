import React, { useEffect, useState } from 'react';
import * as S from './style';
import { arrow } from '../../Assets/index';

interface IProps {
    setPage: (e: any) => any,
    page: any,
    pageCount: any
};

const Paginator: React.FC <IProps> = ({page, setPage, pageCount}) => {
    const [ value, setValue] = useState<number>(page);
    
    useEffect(()=>{
        setPage(value)
    }, [value]);

    return (
        <S.Container>
            <div>
                <S.PrevPage
                    type='button'
                    disabled={value === 1 ? true : false}
                    onClick={() => {
                        let cont = value - 1;
                        setValue(cont)
                    }}
                >
                    <img src={arrow} alt="" />
                </S.PrevPage>

                <S.Page>{value}</S.Page>

                <p>de</p>

                <S.AnotherPage>{pageCount}</S.AnotherPage>

                <S.NextPage
                    type='button'
                    onClick={() => {
                        let cont = value + 1;
                        setValue(cont)
                    }}
                    disabled={pageCount === page + 1 ? false : true}
                >
                    <img src={arrow} alt="" />
                </S.NextPage>
            </div>
        </S.Container>
    );
};

export default Paginator;