import { queryClient } from './../Functions/query/index';
import { IHomepage } from './../Interfaces/IHome';
import { api } from "../Functions";
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const getHomepage = async <T>():Promise<IHomepage []> => {
    const resp = await api.get<IHomepage []>(`/homepages?populate=*&sort=orderby`);

    return resp.data;
};

export const useHomepage = <T>():UseQueryResult<IHomepage> => {
    return useQuery(['homepage'], () => getHomepage(), {
        staleTime: 30 * 1000,
        initialData: () => queryClient.getQueryData(['homepage']),
        initialDataUpdatedAt: () => queryClient.getQueryState(["homepage"])?.dataUpdatedAt,
    });
};