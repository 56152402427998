import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { api } from "../Functions";
import { IPrivacyPolice } from "../Interfaces/IPrivacyPolice";

const getPrivacyPolice = async <T>():Promise<IPrivacyPolice> => {
    const resp = await api.get<IPrivacyPolice>(`/privacy-policies?populate=*&sort=id%3AASC`);

    return resp.data;
};

export const usePrivacyPolice = <T>():UseQueryResult<IPrivacyPolice> => {
    return useQuery(['privacy-police'], () => getPrivacyPolice());
};