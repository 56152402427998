import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { api } from "../Functions";
import { IQuestions } from "../Interfaces/IQuestions";

const getQuestions = async <T>():Promise<IQuestions []> => {
    const resp = await api.get<IQuestions []>(`/common-questions?sort=id%3Adesc`);

    return resp.data;
};

export const useQuestions = <T>():UseQueryResult<IQuestions> => {
    return useQuery(['questions'], () => getQuestions());
};