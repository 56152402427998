import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { sosApi } from "../../Functions/api";
import { getCities, getStates } from "../../Utils/resources";
import ClusterMarker from "./ClusterMarker";

const Maps: React.FC = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const mapRef = React.useRef<google.maps.Map>();
  const map = mapRef.current;
  // const [map, setMap] = React.useState<google.maps.Map>();
  const [services, setServices] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [points, setPoints] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>({
    service: "",
    state: "RJ",
    city: "Rio de Janeiro",
  });

  function initMap() {
    if (ref.current)
      mapRef.current = new window.google.maps.Map(ref.current, {
        // center: { lat: -22.9265873, lng: -43.2269271 },
        center: { lat: -22.9227966, lng: -43.1963752 },
        zoom: 12,
        fullscreenControl: false,
        panControl: false,
        zoomControl: true,
        streetViewControl: false,
        mapTypeControl: false,
      });
    mapRef.current?.addListener("click", function (e: any) {
      console.log("on map click", { lat: e.latLng.lat(), lng: e.latLng.lng() });
    });
  }

  function loadServices() {
    sosApi
      .get("/services", { params: { active: true } })
      .then(async (response) => {
        const data = await response.data;

        setServices(data?.map((d: any) => ({ label: d.name, value: d.id })));
      });
  }

  React.useEffect(() => {
    initMap();
    loadServices();
  }, []);

  useEffect(() => {
    // if (map) {
    if (!filter.city) {
      setPoints([]);
      return;
    }

    // initMap();

    sosApi
      .get("/occurrences/map", {
        params: {
          ...filter,
          services: filter.service,
        },
      })
      .then(async (response) => {
        const data = await response.data;

        setPoints(data);
      });
    // }
  }, [filter]);

  const states = getStates();
  const cities = filter.state ? getCities(filter.state) : [];

  console.log("Map render", { services });

  return (
    // height must be the same as header
    <div style={{ position: "relative", height: "80vh" }}>
      <div ref={ref} id="map" style={{ height: "100%", width: "100%" }}>
        {points?.map((point) => (
          <ClusterMarker key={point.cid} map={map} {...point} />
        ))}
      </div>
      <div
        style={{
          position: "absolute",
          top: "38px",
          right: "100px",
          width: "100%",
          // backgroundColor: "#fff",
          borderRadius: "10px",
          // padding: "15px 25px",
        }}
      >
        <Row className="d-flex justify-content-end">
          <Col xs={8} md={3} xl={2} className="">
            <Form.Select
              aria-label="Selecione uma opção"
              value={filter.service}
              onChange={(e) =>
                setFilter((prev: any) => ({
                  ...prev,
                  service: e.target.value,
                }))
              }
            >
              <option value="">Todos</option>
              {services?.map((s) => (
                <option key={s.value} value={s.value}>
                  {s.label}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={8} md={3} xl={2} className="">
            <Form.Select
              aria-label="Selecione uma opção"
              value={filter.state}
              onChange={(e) =>
                setFilter((prev: any) => ({
                  ...prev,
                  state: e.target.value,
                  city: undefined,
                }))
              }
            >
              <option value="">Selecione um estado</option>
              {states.map((s) => (
                <option key={s.value} value={s.value}>
                  {s.label}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={8} md={3} xl={2} className="ml-3">
            <Form.Select
              aria-label="Selecione uma opção"
              value={filter.city}
              onChange={(e) =>
                setFilter((prev: any) => ({ ...prev, city: e.target.value }))
              }
            >
              <option value="">Selecione uma cidade</option>
              {cities.map((c) => (
                <option key={c.value} value={c.value}>
                  {c.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </div>
      <Col
        style={{
          position: "absolute",
          bottom: "38px",
          left: "38px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "15px 25px",
        }}
        xs={6}
        sm={6}
        md={6}
        lg={4}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "0.8rem",
            textAlign: "start",
            marginBottom: "17px",
          }}
        >
          Escala de horas
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "0.7rem",
            justifyContent: "space-between",
          }}
        >
          <div></div>
          <div>1 dia</div>
          <div>2 dias</div>
          <div>3 dias</div>
          <div>4 dias</div>
          <div>5 dias</div>
          <div>6 dias</div>
          <div>7 dias</div>
        </div>
        <div
          id="gradient"
          style={{
            height: "11px",
            background:
              "linear-gradient(89.95deg, #F77014 20.14%, #F77014 42.85%, #FF000E 57.14%, #341948 111.3%)",
            borderRadius: "65.1244px",
          }}
        ></div>
      </Col>
    </div>
  );
};

export default Maps;
