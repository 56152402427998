import { ibgeApi } from "../Functions";
import { ListUf } from '../Interfaces/IStates';
import { UseQueryResult, useQuery } from "@tanstack/react-query";

const getStates = async ():Promise<ListUf []> => {
    const { data: resp } = await ibgeApi.get<ListUf []>('v1/localidades/estados?orderBy=nome')

    return resp
}

export const useUf = <T>(): UseQueryResult<ListUf []> => {
    return useQuery(["state"], () => getStates())
}