import React from 'react';
import * as S from './style';
import { Helmet } from "react-helmet";
import { Col, Container, Row } from 'react-bootstrap';
import { useFormContato } from '../../Hooks/useFormContato';
import renderHTML from 'react-render-html';

const FormContato: React.FC = () => {
    const { data } = useFormContato();

    return (
        <>
            <Helmet 
                title="Sos - Água e Luz | Formulário de contato"
            />
            {/* <S.Container>
                <div>
                    <h1>{data?.data[0]?.attributes?.title}</h1>
                    {data?.data[0]?.attributes?.description !== undefined ? renderHTML(data?.data[0]?.attributes?.description) : <div />}
                </div>
            </S.Container>*/}
                <div style={{marginTop: "40px", marginBottom: "40px"}}>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6}>
                                <h1>{data?.data[0]?.attributes?.title}</h1>
                                <p>{data?.data[0]?.attributes?.description !== undefined ? renderHTML(data?.data[0]?.attributes?.description) : <div />}</p>
                            </Col>
                            <Col xs={12} lg={6}>
                                {data?.data[0]?.attributes?.htmlCode ? renderHTML(data?.data[0]?.attributes?.htmlCode) : <div/>}
                            </Col>
                        </Row>
                    </Container>
                </div>
            
        </>
    );
};

export default FormContato;