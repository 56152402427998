import styled from "styled-components";
import {
    Card as CardBootstrap
} from 'react-bootstrap';
import { NavLink } from "react-router-dom";

export const Card = styled(CardBootstrap)`
    border: none;
    border-radius: 20px;
    height: 446px;
    position: relative;
    width: 100%;
    cursor: pointer;
`;

export const Link = styled(NavLink)`
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 168px;
    height: 67px;
    background: rgba(23, 115, 226, 0.1);
    border-radius: 20px 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #1773E2;
`;

export const Thumbnail = styled.img`
    height: 200px;
    object-fit: cover;
`;

export const Author = styled.img`

`;

export const Content = styled.div`
    padding-left: 2px;
    padding-right: 2px;
    margin-top: 32px;
    margin-bottom: 32px;
    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;



export const Title = styled.h1`
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #1E3040;
`;

export const Descriprion = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1E3040;
`;

export const Date = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #2C3941;
    opacity: 0.5;
    padding-left: 2px;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
`;

export const Category = styled.p<{ category: string }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    height: 48px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 48px;

    ${props => {
        if(props.category === 'one'){
            return `
                background: rgba(255, 119, 241, 0.1);
                color: #FF77F1;
            `;
        } else if(props.category === 'two'){
            return `
                background: rgba(184, 211, 53, 0.1);
                color: #B8D335;
            `;
        } else if(props.category === 'three'){
            return `
                background: rgba(157, 134, 237, 0.1);
                color: #9D86ED;
            `;
        } else if(props.category === 'for'){
            return `
                color: #47DED0;
                background: rgba(71, 222, 208, 0.1);
            `;
        }
    }}

`;

export const Download = styled.a`
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 168px;
    height: 67px;
    background: rgba(23, 115, 226, 0.1);
    border-radius: 20px 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #1773E2;
    cursor: pointer;
`;