import React, { useEffect } from 'react';
import * as S from './style';
import { Button, Col, Image, Row } from 'react-bootstrap';
import {
    facebookIconShare,
    twitterIconShare,
    telegramIconShare,
    whatsappIconShare,
    linkedinIconShare,
    thumbBlog,
    authorIcon
} from '../../Assets/index';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import { useBlogById } from '../../Hooks';
import renderHTML from 'react-render-html';
import { dateTime } from '../../Utils';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    TelegramShareButton,
} from "react-share";

const PostBlog: React.FC = () => {
    const slug = useParams();
    const { data: post, isLoading } = useBlogById(slug?.slug);
    console.log(post)

    const shareUrl = window.location.href;
    console.log("🚀 ~ file: index.tsx:34 ~ shareUrl:", shareUrl)
    const title = post?.data[0]?.attributes?.title;
    const image = post?.data[0]?.attributes?.thumbnail?.data?.attributes?.url;
    const description = post?.data[0]?.attributes?.description;

    return (
        <S.Content>
            {!isLoading ? (
                <>
                    {post?.data?.map((id: any, index: number) => (
                        <div key={index}>
                            <Helmet>
                                <title>{id?.attributes?.title}</title>
                                <meta property="og:url"                content={shareUrl} />
                                <meta property="og:title"              content={title} />
                                <meta property="og:description"        content={description} />
                                <meta property="og:image"              content={image} />
                                <meta property="og:type"               content="article" />
                                <meta property="og:site_name"          content="SOS Água e Luz."/>
                                <meta content="image/*"                property="og:image:type" />
                                <meta property="og:image:width"        content="600" />
                                <meta property="og:image:height"       content="600" />
                                <meta property="title"                 content={title} />
                                <meta property="quote"                 content={title} />
                                <meta name="twitter:image:alt"         content="Post image"/>
                                <meta property="type"                  content="article" />
                                <meta property="url"                   content={shareUrl} />
                            </Helmet>
                            <S.ImageHeader src={id?.attributes?.cover?.data?.attributes?.url}/>
                            <S.Container>
                                <Row className="mb-4">
                                    <Col>
                                        <S.Title>{id?.attributes?.title}</S.Title>
                                    </Col>
                                </Row>

                                <Row className="mb-2 mt-2">
                                    <Col xs={12}>
                                        <hr />
                                    </Col>
                                </Row>

                                <Row className='d-lg-flex flex-lg-row justify-content-lg-between'>
                                    <Col md={12} lg={6} xl={4} className='d-flex flex-lg-row'>
                                        {/* <Col xs={2} sm={1} lg={2} className="mb-2">
                                            <img src={id?.attributes?.author_picture?.data?.attributes?.url} alt="" />
                                        </Col> */}
                                        <Col xs={12} sm={10} lg={10} className="mb-3">
                                            <S.Author>Por <b>{id?.attributes?.author}</b></S.Author>
                                            <S.Date> {dateTime(id.attributes.publishedAt)}</S.Date>
                                        </Col>
                                    </Col>
                                    <Col lg={12} xl={2}>
                                        <Row className="mb-4 d-flex justify-content-between flex-lg-row justify-content-lg-between align-items-lg-center">
                                            {/* <Col xs={12} lg={2} xl={5}>
                                                Compartilhar: 
                                            </Col> */}
                                            <Col xs={2} lg={2} xl={1}>
                                                <FacebookShareButton
                                                    url={shareUrl}
                                                    quote={title}
                                                    // url={"https://www.facebook.com/sharer/sharer.php?u="+shareUrl} 
                                                    // quote={title}
                                                >
                                                    <Image src={facebookIconShare} className="img-icon"/>
                                                </FacebookShareButton>
                                            </Col>
                                            <Col xs={2} lg={2} xl={1}>
                                                <TwitterShareButton 
                                                    url={shareUrl} 
                                                    title={title} 
                                                >
                                                    <Image src={twitterIconShare} className="img-icon"/>
                                                </TwitterShareButton>
                                            </Col>
                                            <Col xs={2} lg={2} xl={1}>
                                                <WhatsappShareButton 
                                                    url={shareUrl} 
                                                    title={title} 
                                                >
                                                    <Image src={whatsappIconShare} className="img-icon"/>
                                                </WhatsappShareButton>
                                            </Col>
                                            <Col xs={2} lg={2} xl={1}>
                                                <TelegramShareButton
                                                    url={shareUrl} 
                                                    title={title} 
                                                >
                                                    <Image src={telegramIconShare} className="img-icon"/>
                                                </TelegramShareButton>
                                            </Col>
                                            <Col xs={2} lg={2} xl={1}>
                                                <LinkedinShareButton
                                                    url={shareUrl} 
                                                    title={title} 
                                                >
                                                    <Image src={linkedinIconShare} className="img-icon"/>
                                                </LinkedinShareButton>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <S.ContentPost>
                                    {id?.attributes?.content !== undefined ? renderHTML(id?.attributes?.content) : <div />}
                                </S.ContentPost>
                            </S.Container>
                        </div>
                    ))}
                </>
            ) : (
                <Row>
                    <SkeletonTheme baseColor="#F1F1F1" highlightColor="#F7F7F7">
                        <Row>
                            <Skeleton height={120} />
                            <Skeleton height={30} />
                        </Row>
                        <Row className="d-flex justify-content-between mb-5">
                            <Col>
                                <Skeleton height={400} />
                            </Col>
                            <Col>
                                <Skeleton height={30} count={1} width={130}/>
                                <Skeleton height={15} count={15}/>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-between mb-5">
                            <Col>
                                <Skeleton height={15} count={5} />
                            </Col>
                            <Col>
                                <Skeleton height={15} count={5}/>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-between mb-5">
                            <Col>
                                <Skeleton height={400} />
                            </Col>
                            <Col>
                                <Skeleton height={400} />
                            </Col>
                            <Col>
                                <Skeleton height={400} />
                            </Col>
                            <Col>
                                <Skeleton height={400} />
                            </Col>
                        </Row>
                    </SkeletonTheme>
                </Row>
            )}

        </S.Content>
    );
};

export default PostBlog;