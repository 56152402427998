import styled from "styled-components";
import {
    ToggleButton as ReactButton
} from 'react-bootstrap';

export const Content = styled.div`
    background: #F8F8F8;

    @media (max-width: 576px) {
        > div {
            padding: 20px 16px;
        }
    }

    @media (min-width: 577px) and (max-width: 767px) {  
        > div {
            padding: 20px 32px;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) { 
        > div {
            padding: 20px 32px;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {  
        > div {
            padding: 20px 32px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1399px) {  
        > div {
            padding: 20px 32px;
        }
    }

    @media (min-width: 1400px){    
        width: 100%;
        > div {
            margin: 0 auto;
            max-width: 1920px;
            padding: 20px 180px;
        }
    }
`;

export const Button = styled(ReactButton)<{
    checked: any,
    value: any
}>`
    background: none;
    border: none;
    color: #2C394195;

    ${props => {
        if(props.checked === props.value){
            return `
                color: #C2C22C;
            `;
        }
    }}
    
`;