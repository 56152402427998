import React, { useEffect } from 'react';
import { 
  ScrollToTop,
  Header,
  Footer 
} from './Components';
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from './Components/Styles/GlobalStyle';
import AuthRoutes from './Routes';
import { queryClient } from './Functions';
import { QueryClientProvider } from '@tanstack/react-query';

const App: React.FC = () => {

  return (
    <QueryClientProvider client={queryClient}>
      <ScrollToTop>
        <GlobalStyle />
        <Header />
          <AuthRoutes />
        <Footer />
      </ScrollToTop>
    </QueryClientProvider>
  );
}

export default App;