import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { sosApi } from "../Functions";
import { ISource } from '../Interfaces/ISources';

const getSources = async <T>():Promise<ISource []> => {
    const resp = await sosApi.get<ISource []>(`/sources`);

    return resp.data;
};

export const useSources = <T>():UseQueryResult<ISource []> => {
    return useQuery(['sources'], () => getSources());
};