import styled from 'styled-components';
import CountUp from 'react-countup';

export const Number = styled(CountUp)`
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 68px;
    color: #2C3941;
    letter-spacing: 19px;
`;
