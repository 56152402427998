import styled from "styled-components";
import {
    rodapeBg,
    rodapeRespBg
} from '../../Assets/index'
import { NavLink } from 'react-router-dom';
import {
    Container as ContainerBootstrap
} from 'react-bootstrap';

export const Container = styled.div`
    background: #2C3941;
    height: auto;
    z-index: 999;

    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 48px;
        color: #4D9DFF;
    }

    hr {
        border: 1px solid #B6B6B6;
        opacity: 0.5;
        width: 90%;
        height: 0px;
        margin: 40px auto;
    }

    @media (max-width: 576px) {
        padding-top: 103px;
        padding-bottom: 40px;
        background: URL(${rodapeRespBg});
    }

    @media (min-width: 577px) and (max-width: 767px) {  
        padding-top: 103px;
        padding-bottom: 40px; 
        background: url(${rodapeBg}) no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    @media (min-width: 768px) and (max-width: 991px) { 
        padding-top: 103px;
        padding-bottom: 40px;    
        background: url(${rodapeBg}) no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    @media (min-width: 992px) and (max-width: 1199px) {  
        padding-top: 103px;
        padding-bottom: 40px;   
        background: url(${rodapeBg}) no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        
        hr {
            width: 100%;
        }
    }

    @media (min-width: 1200px) and (max-width: 1399px) {  
        padding-top: 103px;
        padding-bottom: 40px;  
        background: url(${rodapeBg}) no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        
        hr {
            width: 100%;
        }  
    }

    @media (min-width: 1400px){       
        padding-top: 103px;
        padding-bottom: 40px; 
        background: url(${rodapeBg}) no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        
        hr {
            width: 100%;
        }
    }
`;

export const Div = styled(ContainerBootstrap)`
  
`;


export const Link = styled(NavLink)`
    text-decoration: none;
    color: #FFFFFF;
`;

export const Button = styled.button`
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;

    > img {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(300px);
        border-radius: 100%;
        margin-left: 10px;
    }
`;

export const Contact = styled.div`
    color: #FFFFFF;

    > div {
        display: flex;
        align-items: center;

        > p {
            margin: 0 10px !important;
        }
    }

    @media (max-width: 576px) {
        display: flex;
        flex-direction: column;

        > div {
            margin-bottom: 10px;
        }
        
    }

    @media (min-width: 577px) and (max-width: 767px) {  
        display: flex;
        flex-direction: row;
    }

    @media (min-width: 768px) and (max-width: 991px) {   
        display: flex;
        flex-direction: row;  
    }

    @media (min-width: 992px) and (max-width: 1199px) {   
        display: flex;
        flex-direction: row;  
    }

    @media (min-width: 1200px) and (max-width: 1399px) {  
        display: flex;
        flex-direction: row;  
    }

    @media (min-width: 1400px){        
        display: flex;
        flex-direction: row;
    }
`;

export const ContentLogo = styled.div`
    > img {
        width: 244.28px;
        height: 67.67px;
    }

    @media (max-width: 576px) {
        display: none;
    }

    @media (min-width: 577px) and (max-width: 767px) {  
        display: none;
    }

    @media (min-width: 768px) and (max-width: 991px) {   
        
    }

    @media (min-width: 992px) and (max-width: 1199px) {   
        
    }

    @media (min-width: 1200px) and (max-width: 1399px) {  

    }

    @media (min-width: 1400px){        

    }
`;