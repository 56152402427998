import React, { useState } from 'react';
import * as S from './style';
import { Card, Paginator } from '../../Components';
import { dateTime } from '../../Utils/index';
import { 
    Col,
    Container,
    Form,
    InputGroup,
    Row
} from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { useBlog } from '../../Hooks';
import { Helmet } from "react-helmet";

const Blog: React.FC = () => {
    const [ page, setPage ] = useState<number>(1);
    const [ title, setTitle ] =useState()
    const { data: blog, isLoading } = useBlog(page, title);

    console.log(blog, 'blogblog')

    return (
        <S.Content>
            <Helmet 
                title="Sos - Água e Luz | Notícias"
            />
            <div>
                <Row>
                    <Col xl={4}>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Pesquisar"
                                aria-label="search"
                                aria-describedby="basic-addon1"
                                onChange={(e: any) => {
                                    setTitle(e?.target?.value)
                                }}
                            />
                        </InputGroup>
                    </Col>
                </Row>

                {!isLoading ?
                    (
                        <Row>
                            {blog?.data.map((id: any, index: number) => {
                                return (
                                    <Col 
                                        className="mb-4"
                                        xs={12} 
                                        sm={6} 
                                        md={6} 
                                        lg={index === 0 ? 6 : 3}
                                    >
                                        <Card
                                            type='blog' 
                                            index={index}
                                            open={`${id?.attributes?.slug}`} 
                                            date={dateTime(id.attributes.publishedAt)}   
                                            title={id.attributes.title} 
                                            desciption={id.attributes.description} 
                                            icon={id.attributes.icon} 
                                            thumbnail={id?.attributes?.thumbnail?.data?.attributes?.url} 
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                    ) : (
                        <SkeletonTheme baseColor="#F1F1F1" highlightColor="#F7F7F7">
                            <Row className="d-flex justify-content-between mb-5">
                                <Col>
                                    <Skeleton height={400} />
                                </Col>
                                <Col>
                                    <Skeleton height={400} />
                                </Col>
                                <Col>
                                    <Skeleton height={400} />
                                </Col>
                                <Col>
                                    <Skeleton height={400} />
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-between mb-5">
                                <Col>
                                    <Skeleton height={400} />
                                </Col>
                                <Col>
                                    <Skeleton height={400} />
                                </Col>
                                <Col>
                                    <Skeleton height={400} />
                                </Col>
                                <Col>
                                    <Skeleton height={400} />
                                </Col>
                            </Row>
                        </SkeletonTheme>
                    )
                }

                <Paginator
                    pageCount={blog?.meta?.pagination?.pageCount} 
                    page={page}
                    setPage={(e: any) => {
                        setPage(e);
                    }}
                />
            </div>
        </S.Content>
    );
};

export default Blog;