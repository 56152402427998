import { NavLink } from 'react-router-dom';
import styled from "styled-components";
import { 
    Form as FormReact,
    Button as ReactButton
} from 'react-bootstrap';

export const Form = styled(FormReact)`
    .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #FFFFFF !important;
        opacity: 1; /* Firefox */
    }

    .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #FFFFFF;
    }

    .form-control::-ms-input-placeholder { /* Microsoft Edge */
        color: #FFFFFF;
    }

    @media (max-width: 576px){
        margin-top: 40px;
    }

    p {
        color: #FFFFFF;
    }
`;

export const Input = styled(FormReact.Control)`
    background: none;
    color: #FFFFFF !important;
    height: 59px;
    border: 1px solid #B6B6B6 !important;
    border-radius: 8px !important;

    :focus {
        background: none !important;
    }
`;

export const Select = styled(FormReact.Select)`
    background: none;
    color: #FFFFFF !important;
    height: 59px;
    border: 1px solid #B6B6B6 !important;
    border-radius: 8px !important;
    
    > option {
        color: #2C3941;
    }
`;

export const Button = styled(ReactButton)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    height: 54px;
    background: #2261AC;
    border-radius: 10px;
    border: none;
    width: 100%;
`;

export const TitleForm = styled.h1`
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 39px;
    color: #FFFFFF;

    @media (max-width: 576px){
        width: 320px;
    }
`;

export const Link = styled(NavLink)`
    text-decoration: underline;
    color: #FFFFFF;
    font-weight: 700;
`;