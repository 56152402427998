import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { api } from "../Functions";
import { IHomecards } from '../Interfaces/IHomeCards';

const getHomepagecards = async <T>():Promise<IHomecards []> => {
    const resp = await api.get<IHomecards[]>(`/homepages-blocks?populate=*`);

    return resp.data;
};

export const useHomepagecards = <T>():UseQueryResult<IHomecards > => {
    return useQuery(['homepage-block'], () => getHomepagecards());
};