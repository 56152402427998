import React from 'react';
import * as S from './style';
import { Helmet } from "react-helmet";
import { usePrivacyPolice } from '../../Hooks/usePrivacyPolice';
import renderHTML from 'react-render-html';

const PoliticaDePrivacidade: React.FC = () => {
    const { data } = usePrivacyPolice();

    return (
        <>
            <Helmet 
                title="Sos - Água e Luz | Politica de privacidade"
            />
            <S.Container>
                <div>
                    <h1>{data?.data[0]?.attributes?.title}</h1>
                    {data?.data[0]?.attributes?.description !== undefined ? renderHTML(data?.data[0]?.attributes?.description) : <div />}
                </div>
            </S.Container>
            {/* <S.Container>
                <div>
                    <h1>Política de Privacidade e Proteção de Dados da ComCat</h1>
                    <h2>Introdução</h2>
                    <p>A Comunidades Catalisadoras (ComCat) reconhece o dever de preservar a privacidade de seus colaboradores e do público em geral em todas suas atividades, sites e aplicativos. Este dever se torna ainda mais importante para a instituição tendo em vista a situação de vulnerabilidade de muitos dos colaboradores da instituição, bem como a sensibilidade de diversos assuntos abordados nas matérias e eventos promovidos pela ComCat.</p>
                    <p>São quatro os projetos atualmente desenvolvidos pela ComCat, que geram contatos, além da organização como um todo: RioOnWatch, Rede Favela Sustentável, Termo Territorial Coletivo, e Painel Unificador das Favelas com seu novo projeto SOS Água e Luz.</p>
                    <p>São sete os sites atualmente mantidos e geridos pela ComCat: </p>
                    <a href="www.comcat.org" target="_blank">www.comcat.org</a>
                    <a href="www.rioonwatch.org" target="_blank">www.rioonwatch.org</a>
                    <a href="www.rioonwatch.org.br " target="_blank">www.rioonwatch.org.br </a>
                    <a href="www.termoterritorialcoletivo.org" target="_blank">www.termoterritorialcoletivo.org</a>
                    <a href="www.favelasustentavel.org" target="_blank">www.favelasustentavel.org</a>
                    <a href="www.favela.info " target="_blank">www.favela.info </a>
                    <a href="www.sosaguaeluz.org" target="_blank">www.sosaguaeluz.org</a>
                    <h2>Aceitação dos Termos de Uso do Aplicativo</h2>
                    <p>Ao baixar e usar o aplicativo SOS Água e Luz, você concorda em cumprir estes termos
de uso. Caso você não concorde com esses termos, por favor, não use o aplicativo.</p>


                    <h2>Funcionamento do Aplicativo</h2>
                    <p>O aplicativo SOS Água e Luz tem como objetivo permitir que os usuários reportem
ocorrências de falta de água e luz em suas localidades. As ocorrências serão exibidas
em um mapa para que os usuários possam visualizar as áreas afetadas.</p>


                    <h2>Uso do GPS</h2>
                    <p>O aplicativo usa a localização GPS do seu dispositivo para facilitar a inclusão de novos
registros e para redirecionar o mapa para a localização atual do usuário. A localização
será coletada somente enquanto o aplicativo estiver aberto.</p>


                    <h2>Uso da Informação</h2>
                    <p>Ao usar o aplicativo, você concorda em fornecer informações precisas e verdadeiras.
Nós nos reservamos o direito de excluir qualquer registro que seja considerado
enganoso ou impreciso.</p>


                    <h2>Proteção de Dados Pessoais</h2>
                    <p>Respeitamos a privacidade dos usuários e tomamos medidas para proteger suas
informações pessoais. Por favor, consulte nossa política de privacidade para mais
informações.</p>


                    <h2>Modificações dos Termos de Uso</h2>
                    <p>Nos reservamos o direito de alterar estes termos de uso a qualquer momento.
Qualquer alteração será publicada nesta página e entrará em vigor imediatamente. É
de sua responsabilidade revisar regularmente estes termos para verificar quaisquer
alterações.</p> 


                    <h2>Interrupção do Serviço</h2>
                    <p>Nos reservamos o direito de interromper ou encerrar o aplicativo a qualquer momento,
sem aviso prévio. Não nos responsabilizamos por qualquer perda ou dano decorrente
da interrupção ou encerramento do aplicativo.</p> 

                    <h2>Limitado de Responsabilidade</h2>
                    <p>O aplicativo é fornecido "como está" e "conforme disponível". Não nos
responsabilizamos por quaisquer danos, incluindo, sem limitação, danos diretos,
indiretos, especiais, consequenciais ou punitivos, decorrentes do uso do aplicativo ou
da impossibilidade de usá-lo.</p> 

                    <h2>Dados recolhidos</h2>
                    <p>A ComCat recolhe dados fornecidos voluntariamente por seus colaboradores e público em geral, especialmente por: (i) formulário de inscrição em atividades e eventos; (ii) contribuições feitas pelos colaboradores durante eventos públicos ou organizados por nós; (iii) formulários de contato nos sites; (iv) avaliações regulares das nossas atividades; (v) mapeamento de contatos, projetos sociais, suas demandas e territórios; e (vi) contribuições para matérias jornalísticas. Eventualmente, outros tipos de dados não previstos expressamente nesta política de privacidade podem ser coletados, porém são protegidos da mesma forma por ela.</p>
                    <p>Alguns destes dados são considerados sensíveis, mesmo que não previstos no rol do art. 11 da Lei 13.709/2018 (Lei Geral de Proteção de Dados Pessoais), e com isso se faz necessária uma política de privacidade explícita e de máxima força.</p>
                    <h2>Compartilhamento de dados</h2>
                    <p>A ComCat não compartilha dados pessoais de seus colaboradores ou cuja fonte possa ser identificada, bem como quaisquer contatos de forma geral com terceiros, exceto em casos em que haja a expressa autorização dos proprietários dos dados para este fim, de forma específica.</p>
                    <p>Os membros e colaboradores da equipe da ComCat autorizados a terem acesso aos contatos não são permitidos a fazer uso destes para nenhum fim a não ser o expressamente definido pelo contato em sua relação com a instituição.</p>
                    <h2>Armazenamento de dados</h2>
                    <p>Os dados pessoais coletados são armazenados pela ComCat por tempo indeterminado, sempre considerando o direito de seus titulares de solicitar sua exclusão dos arquivos da instituição a qualquer momento.</p>
                    <h2>Medidas de segurança no tratamento dos dados</h2>
                    <p>A ComCat emprega medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados, bem como de situações de destruição, perda, extravio ou alteração dos mesmos.</p>
                    <p>Nenhuma planilha ou outro documento que contenha contatos da ComCat é disponibilizado de forma pública ou parcialmente pública; são todas mantidas como privadas, com autorização de acesso só para membros da equipe que assinem um contrato do qual conste o comprometimento com essa política de privacidade.</p>
                    <p>A concessão de autorização de acesso a tais documentos leva em consideração a natureza dos dados, o contexto e a finalidade do tratamento, bem como os riscos que uma eventual violação geraria para os direitos e liberdades do contato.</p>
                    <p>Entre as medidas de segurança adotadas por nós, destacamos: (i) restrições de acesso aos bancos de dados institucionais; (ii) back-ups de todos os sistemas; e (iii) sistema de relacionamento descentralizado.</p>
                    <p>Apesar da adoção de todas as medidas de segurança para evitar incidentes de insegurança, é possível que ocorram incidentes motivados exclusivamente por terceiros estranhos à instituição, caso de ataques de hackers ou crackers por exemplo. Nestas situações excepcionais, a ComCat se isenta de responsabilidade pelo vazamento de dados.</p>
                    <p>De qualquer forma, em caso de qualquer tipo de incidente de segurança que possa gerar risco ou dano para qualquer pessoa, a ComCat comunicará imediatamente os afetados e a autoridade nacional de proteção de dados acerca do ocorrido, em conformidade com a Lei Geral de Proteção de Dados (Lei 13.709/18).</p>
                    <h2>Procedimentos de Denúncia</h2>
                    <p>A ComCat disponibiliza os meios seguros listados abaixo, apropriados e acessíveis para denúncias relacionadas à política de privacidade a serem utilizados por colaboradores, voluntários, mobilizadores sociais e moradores.</p>
                    <p>Assim, qualquer denúncia ou preocupação sobre o tema deverá ser encaminhada à diretoria executiva da ComCat , sendo possível o recebimento de manifestações anônimas sobre o tema.</p>
                    <p>As denúncias e/ou preocupações de colaboradores, voluntários, mobilizadores sociais ou moradores serão avaliadas internamente e respondidas em até 5 dias. A resposta deverá conter as medidas adotadas pela ComCat para mitigação dos riscos e medidas de suporte em caso de eventual prejuízo já estabelecido.</p>
                    <h2>Confidencialidade</h2>
                    <p>A ComCat reafirma o caráter confidencial dos dados por ela coletados, sendo vedada sua cessão ou transferência para qualquer agente, ressalvados os casos em que haja prévia autorização dos envolvidos.</p>
                    <h2>Legislação</h2>
                    <p>Essa política foi estabelecida com base na legislação e jurisprudência brasileiras de proteção de dados, em especial em conformidade com a Lei 13.709 de 2018.</p>
                    <p>A ComCat reforça seu compromisso de estabelecer arranjos protetivos que reflitam a importância da proteção dos dados de seus colaboradores e do público em geral.</p>
                    <h2>Contatos Importantes</h2>
                    <span>Diretoria executiva da ComCat</span>
                    <p> Nome: Theresa Williamson</p>
                    <span>Diretora institucional da ComCat</span>
                    <p>Nome: Roseli Franco</p>
                    <p>contato@comcat.org</p>
                    <p>Assinado: 1 de julho de 2022</p>
                </div>
            </S.Container> */}
        </>
    );
};

export default PoliticaDePrivacidade;