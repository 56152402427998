import React from "react";
import * as S from "./style";
import {
  map,
  rollArow,
  blueThunder,
  purpleThunder,
  greenThunder,
  orangeThunder,
  aboutThree,
  learnMore,
  appOne,
  appTwo,
  appThree,
  thumbBlog,
  authorIcon,
  blueApple,
  bluePlayStore,
} from "../../Assets/index";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Card, ContUp } from "../../Components";
import { dateTime } from "../../Utils";
import { useBlog, useHomepage, useHomepagecards, useOccurrences } from "../../Hooks";
import renderHTML from "react-render-html";
import { Helmet } from "react-helmet";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Maps from "../../Components/Maps";

const Home: React.FC = () => {
  const { data: homepage, isLoading } = useHomepage();
  const { data: homepagecards } = useHomepagecards();
  const { data: blog } = useBlog(1);
  const { data: occurrences } = useOccurrences("", "", "", "", "", "", "", "", "", "", "", "");

  console.log(occurrences, 'ééé')

  return (
    <>
      <Helmet 
          title="Sos - Água e Luz"
      />
      {!isLoading ? (
        <>
          {homepage?.data?.map((id: any, index: number) => {
            if (index === 0) {
              return (
                <>
                  <S.Container key={index}>
                    <div>
                      <header>
                        <div>
                          <Maps />
                        </div>
                      </header>
                      <br></br>
                      <S.Section id="cards">
                        <h2>
                          Um Aplicativo de Monitoramento de Justiça Hídrica e
                          Energética
                        </h2>
                        <h1>{id?.attributes?.title}</h1>
                        <p>{renderHTML(id?.attributes?.content)}</p>
                        <Row
                          className="
                                                        d-flex flex-column justify-content-center align-items-center
                                                        p-lg-4
                                                        flex-lg-row flex-lg-wrap
                                                        p-xl-5
                                                        m-lg-0
                                                        m-xl-5
                                                    "
                        >
                          {homepagecards?.data?.map((id: any, index) => {
                            return (
                              <Col
                                className="mb-sm-4"
                                xs={10}
                                sm={10}
                                md={6}
                                lg={6}
                                xl={3}
                                key={index}
                              >
                                <S.Card>
                                  <img
                                    src={
                                      id?.attributes?.icon?.data?.attributes
                                        ?.url
                                    }
                                    alt=""
                                  />
                                  <h1>{id?.attributes?.title}</h1>
                                  <p>{renderHTML(id?.attributes?.content)}</p>
                                </S.Card>
                              </Col>
                            );
                          })}
                        </Row>
                      </S.Section>
                    </div>
                  </S.Container>
                </>
              );
            }

            if (index === 1) {
              return (
                <>
                  <S.OurDate>
                    <h2>nossos dados</h2>
                    <h1>{id?.attributes?.title}</h1>
                    <p>{id?.attributes?.content}</p>
                  </S.OurDate>
                  <S.NumbersContent>
                    <Row
                      style={{ background: "#F8F8F8" }}
                      className="
                                                d-flex flex-column justify-content-start align-items-center
                                                flex-sm-column justify-content-sm-center align-items-sm-center
                                                flex-md-row justify-content-md-center 
                                                align-items-lg-center
                                                pb-xl-5
                                            "
                    >
                      <Col
                        className="
                                                    d-flex flex-column justify-content-start align-items-center
                                                    flex-sm-column justify-content-sm-center align-items-sm-center
                                                    flex-md-column justify-content-md-center 
                                                "
                        xs={6}
                        sm={9}
                        md={5}
                        lg={3}
                      >
                        <ContUp number={occurrences?.line_charts[0]?.total} />
                        <S.Description>
                          {" "}
                          Casos de falta de energia reportados
                        </S.Description>
                      </Col>
                      <Col
                        className="
                                                    d-flex flex-column justify-content-start align-items-center
                                                    flex-sm-column justify-content-sm-center align-items-sm-center
                                                    flex-md-column justify-content-md-center 
                                                "
                        xs={6}
                        sm={9}
                        md={5}
                        lg={3}
                      >
                        <ContUp number={occurrences?.line_charts[1]?.total} />
                        <S.Description>
                          Casos de falta de água reportados
                        </S.Description>
                      </Col>
                      <Col
                        className="
                                                    d-flex flex-column justify-content-start align-items-center
                                                    flex-sm-column justify-content-sm-center align-items-sm-center
                                                    flex-md-column justify-content-md-center 
                                                "
                        xs={6}
                        sm={9}
                        md={5}
                        lg={3}
                      >
                        <ContUp number={occurrences?.total} />
                        <S.Description> Total do occorências registradas</S.Description>
                      </Col>
                      <Col
                        className="
                                                    d-flex flex-column justify-content-start align-items-center
                                                    flex-sm-column justify-content-sm-center align-items-sm-center
                                                    flex-md-column justify-content-md-center 
                                                "
                        xs={6}
                        sm={9}
                        md={5}
                        lg={3}
                      >
                        <ContUp number={occurrences?.new_today} />
                        <S.Description> Occorências registradas hoje</S.Description>
                      </Col>
                    </Row>
                  </S.NumbersContent>

                  {/* <S.SectionNews>
                    <Row
                      className="p-5 
                                                d-xxl-flex flex-xxl-row justify-content-xxl-between
                                                d-sm-flex flex-md-wrap justify-content-sm-between
                                            "
                    >
                      <Col xxl={5} sm={5} xl={5}>
                        <h1>Cadastre-se em nossa newsletter gratuitamente!</h1>
                        <p><b>Receba</b> por e-mails notícias e <b>relatórios</b></p>
                      </Col>
                      <Col sm={5} lg={4} xxl={4}>
                        <Form action="">
                          <Col xxl={10} className="mb-3">
                            <Form.Control
                              placeholder="E-mail"
                              inputMode="email"
                            />
                          </Col>
                          <Col xxl={10}>
                            <Button
                              style={{ background: " #1773E2", width: "100%" }}
                              className="mb-3"
                              type="button"
                            >
                              Cadastrar
                            </Button>
                          </Col>

                          <p>
                            Ao se cadastrar, você concorda com os{" "}
                            <NavLink
                              to="/politica-de-privacidade"
                              style={{ color: "#FFFFFF" }}
                            >
                              termos de uso
                            </NavLink>
                            .
                          </p>
                        </Form>
                      </Col>
                    </Row>
                  </S.SectionNews> */}
                </>
              );
            }

            if (index === 2) {
              return (
                <>
                  <S.SectionAbout>
                    <div>
                      <Row
                        className="
                                                    d-flex flex-column justify-content-center align-items-center
                                                    flex-md-row
                                                    pb-md-4
                                                    p-lg-4
                                                    justify-content-lg-between
                                                "
                      >
                        <Col xs={11} md={5}>
                          <S.News>
                            <h2>SOBRE NÓS</h2>
                            <h1>{id?.attributes?.title}</h1>
                            <p>{renderHTML(id?.attributes?.content)}</p>
                            <S.LearnMore to="/sobre">
                              Mais sobre nós
                              <Image src={learnMore} alt="" />
                            </S.LearnMore>
                          </S.News>
                        </Col>
                        <Col xs={11} md={5} className="mt-5 mb-5">
                          <Image
                            fluid
                            src={id?.attributes?.image?.data?.attributes?.url}
                          />
                        </Col>
                      </Row>
                    </div>
                  </S.SectionAbout>
                  <S.SectionImg>
                    <div>
                      <S.MockAppSection>
                        <div>
                          <img className="imgOne" src={appOne} alt="" />
                        </div>
                        <div>
                          <img className="imgTwo" src={appTwo} alt="" />
                          <img className="imgThree" src={appThree} alt="" />
                        </div>
                      </S.MockAppSection>
                    </div>
                  </S.SectionImg>
                  <S.DownloadNow>
                    <div>
                      <Row
                        style={{
                          paddingTop: "150px",
                          paddingLeft: "32px",
                          paddingRight: "32px",
                          paddingBottom: "40px",
                        }}
                        className="d-flex flex-sm-wrap justify-content-lg-between"
                      >
                        <Col xxs={11} sm={6} lg={6}>
                          <h1>Baixe nosso aplicativo!</h1>
                          <p>
                            Nos ajude a registrar todas as ocorrências de falta
                            de energia e de abastecimento de água na sua região.
                          </p>
                          <Row
                            className="
                                                            d-flex flex-row justify-content-between
                                                            justify-content-lg-start
                                                        "
                          >
                            {/* <Col xs={6} sm={9} lg={4} className="mb-2">
                              <S.DownloadApp href="#">
                                <img height={24} src={blueApple} alt="" />
                                Apple Store
                              </S.DownloadApp>
                            </Col> */}
                            <Col xs={6} sm={9} lg={4}>
                              <S.DownloadApp href="https://play.google.com/store/apps/details?id=br.com.sosaguaeluz" target="_blank">
                                <img height={24} src={bluePlayStore} alt="" />
                                Google Play
                              </S.DownloadApp>
                            </Col>
                          </Row>
                        </Col>
                        <Col xxs={11} sm={6} lg={4} className="mt-3 d-flex">
                          <S.ShowMap 
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth"
                              })
                            }}
                          >
                            Explorar mapa
                            <Image src={learnMore} alt="" />
                          </S.ShowMap>
                        </Col>
                      </Row>
                    </div>
                  </S.DownloadNow>
                  <S.LastPost>
                    <div>
                      <S.LastPosts>
                        <h1>Últimas notícias</h1>
                        <Row
                          className="
                                                        d-sm-flex flex-sm-wrap justify-content-sm-between
                                                        d-md-flex flex-md-wrap justify-content-md-between
                                                    "
                        >
                          {blog?.data.map((id: any, index: number) => {
                            if (index <= 3) {
                              return (
                                <Col
                                  className="mb-4"
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={3}
                                >
                                  <Card
                                    type="blog"
                                    index={index}
                                    open={`/noticias/${id.attributes.slug}`}
                                    date={dateTime(id.attributes.publishedAt)}
                                    title={id.attributes.title}
                                    desciption={id.attributes.description}
                                    icon={id.attributes.icon}
                                    thumbnail={
                                      id?.attributes?.thumbnail?.data
                                        ?.attributes?.url
                                    }
                                  />
                                </Col>
                              );
                            }
                          })}
                        </Row>
                        <Row
                          clssName="
                                                        mt-5
                                                    "
                        >
                          <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            xxl={3}
                            className="mt-xxl-5"
                          >
                            <S.ShowMap
                              onClick={() => {
                                window.scrollTo({
                                  top: 0,
                                  behavior: "smooth"
                                })
                              }}
                            >
                              Explorar mapa
                              <Image src={learnMore} alt="" />
                            </S.ShowMap>
                          </Col>
                        </Row>
                      </S.LastPosts>
                    </div>
                  </S.LastPost>
                </>
              );
            }
          })}
        </>
      ) : (
        <Row>
          <SkeletonTheme baseColor="#F1F1F1" highlightColor="#F7F7F7">
            <Row className="d-flex justify-content-between mb-5">
              <Col>
                <Skeleton height={400} />
              </Col>
              <Col>
                <Skeleton height={400} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-between mb-5">
              <Col>
                <Skeleton height={15} count={5} />
              </Col>
              <Col>
                <Skeleton height={15} count={5} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-between mb-5">
              <Col>
                <Skeleton height={400} />
              </Col>
              <Col>
                <Skeleton height={400} />
              </Col>
              <Col>
                <Skeleton height={400} />
              </Col>
              <Col>
                <Skeleton height={400} />
              </Col>
            </Row>
          </SkeletonTheme>
        </Row>
      )}
    </>
  );
};

export default Home;
