import React, { useState } from 'react';
import * as S from './style';
import { NavLink } from 'react-router-dom';
import {
    logo
} from '../../Assets/index';
import {
    hamburger,
    close
} from '../../Assets/index';

const Header: React.FC = () => {
    const [ open, setOpen ] = useState(false);
    return (
        <>
            <S.Navigation>
                <S.NavTop>
                    <div>
                        <div>
                            <S.NavLinkTop to="/politica-de-privacidade">Política de privacidade</S.NavLinkTop>
                            <S.NavLinkTop to="/perguntas-frequentes">Perguntas frequentes</S.NavLinkTop>
                        </div>
                    </div>
                </S.NavTop>
                <S.Navbar>
                    <S.Container>
                        <NavLink to="/">
                            <img src={logo} alt="" />
                        </NavLink>
                        <S.Nav>
                            <S.Link to='/'>HOME</S.Link>
                            <S.Link to='/sobre'>SOBRE NÓS</S.Link>
                            <S.Link to='/dados'>DADOS</S.Link>
                            <S.Link to='/noticias'>NOTÍCIAS</S.Link>
                            <S.Link to='/faleconosco'><S.Button>FALE CONOSCO</S.Button></S.Link>
                        </S.Nav>
                    </S.Container>
                </S.Navbar>
            </S.Navigation>

            <S.RespNav>
                <S.MenuNav>
                    <NavLink to="/">
                        <img src={logo} alt="" />
                    </NavLink>
                    <div>
                    <S.Link to='/faleconosco'><S.Button>FALE CONOSCO</S.Button></S.Link>
                        <button
                            type='button'
                            onClick={() => {
                                setOpen(!open)
                            }}
                        >
                            <img src={open === true ? close : hamburger} alt="" />
                        </button>
                    </div>
                </S.MenuNav>
                {open === true && (
                    <S.LinkMenuResp>
                        <S.LinkNavResp to="/">HOME</S.LinkNavResp>
                        <S.LinkNavResp to="/sobre">SOBRE NÓS</S.LinkNavResp>
                        <S.LinkNavResp to="/dados">DADOS</S.LinkNavResp>
                        <S.LinkNavResp to="/noticias">NOTÍCIAS</S.LinkNavResp>
                        <S.LinkNavResp to="/politica-de-privacidade">POLÍTICA DE PRIVACIDADE</S.LinkNavResp>
                        <S.LinkNavResp to="/perguntas-frequentes">PERGUNTAS FREQUENTES</S.LinkNavResp>
                        <S.LinkNavResp to="/faleconosco">FALE CONOSCO</S.LinkNavResp>
                    </S.LinkMenuResp>   
                )}
            </S.RespNav>

        </>
    );
};

export default Header;