import React from 'react';
import { Accordion as AccordionBt} from 'react-bootstrap/';
import renderHTML from 'react-render-html';

interface IProps {
    title: string,
    content: string,
    eventKey: number
}

const Accordion: React.FC <IProps> = ({title, content, eventKey}) => {

    function setString(value: number){
        return value.toString()
    }

    return (
        <AccordionBt>
            <AccordionBt.Item eventKey={setString(eventKey)}>
                <AccordionBt.Header>{title}</AccordionBt.Header>
                <AccordionBt.Body>
                    {renderHTML(content)}
                </AccordionBt.Body>
            </AccordionBt.Item>
        </AccordionBt>
    );
};

export default Accordion;