import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    > div {
        width: 216px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > p {
            margin: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 32px;
            color: #2C3941;
        }
    }

`;

export const PrevPage = styled.button<{disabled: boolean}>`
    background: none;
    border: 1px solid #2C3941;
    border-radius: 50%;

    > img {
        rotate: 180deg;
    }
    opacity: ${props => props.disabled ? '0.5' : ''};
`;

export const NextPage = styled.button<{disabled: boolean}>`
    background: none;
    border: 1px solid #2C3941;
    border-radius: 50%;
    opacity: ${props => props.disabled ? '0.5' : ''};
`;

export const Page = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    width: 35px;
    height: 34px;
    background: #B6B6B6;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #2C3941;
`;

export const AnotherPage = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #2C3941;
`;