import axios from "axios";

const token =
  "e966bbc02f1e5628b44337c0c046dd2d781de656a6930cf35abdcfe9c2cd4b6839c0cd40bc87ade368af4fc460998ebfe7bf6247d4504ed07d0e61977d5287e3bd58b94b9d8ab6c69837548cc8cfe6c831fedb54062aa357bac67eace874836cc61b0e72312dc5466457d220daa8e95aca4660662d79c2daead67e72f48e21e1";
localStorage.setItem("token", token);

export const api = axios.create({
  baseURL: "https://sea-turtle-app-qoncj.ondigitalocean.app/v1",
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    //'Access-Control-Allow-Origin': '*'
  },
});

export const sosApi = axios.create({
  baseURL: "https://app.sosaguaeluz.org",
  // baseURL: "http://localhost:5003",
  //   headers: {
  //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     //'Access-Control-Allow-Origin': '*'
  //   },
});

export const ibgeApi = axios.create({
  baseURL: "https://servicodados.ibge.gov.br/api",
});
