import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { sosApi } from "../Functions";
import { IServices } from '../Interfaces/IServices';

const getServices = async <T>():Promise<IServices []> => {
    const resp = await sosApi.get<IServices []>(`/services`);

    return resp.data;
};

export const useServices = <T>():UseQueryResult<IServices []> => {
    return useQuery(['services'], () => getServices());
};