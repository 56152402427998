import React, { useState } from 'react';
import * as S from './style';
import { Waypoint } from "react-waypoint";

interface IProps {
    number?: number;
}

const ContUp: React.FC <IProps> = ({number}) => {
    const [viewPortEntered, setViewPortEntered] = useState(false);

    const onVWEnter = () => {
      setViewPortEntered(true);
    };

    return (
        <>
            <Waypoint 
                onEnter={onVWEnter}
            >
                {viewPortEntered ? 
                    <S.Number
                        end={number ? number : 0}
                        start={0}
                        duration={5}
                    />
                : null}
            </Waypoint>
        </>
    );
};

export default ContUp;