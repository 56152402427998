import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Card, Paginator } from '../../Components';
import { useReports } from '../../Hooks';
import { dateTime } from '../../Utils';
import renderHTML from 'react-render-html';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const Relatorio: React.FC = () => {
    const [ page, setPage ] = useState<number>(1);
    const { data: reports, isLoading } = useReports(page);

    return (
        <>
            {!isLoading ? (
                <Row>
                    {reports?.data?.map((id: any, index: number) => {
                        return (
                            <Col 
                                className="mb-4"
                                xs={12} 
                                sm={6} 
                                md={6} 
                                lg={index === 0 ? 6 : 3}
                            >
                                <Card
                                    type='reports' 
                                    index={index}
                                    open={id?.attributes?.file?.data?.attributes?.url} 
                                    date={dateTime(id?.attributes?.updatedAt)}   
                                    title={id?.attributes?.title} 
                                    desciption={renderHTML(id?.attributes?.description)} 
                                    icon={id.icon} 
                                    thumbnail={id?.attributes?.thumbnail?.data?.attributes?.url} 
                                />
                            </Col>
                        )
                    })}
                </Row>
            ) : (
                <SkeletonTheme baseColor="#F1F1F1" highlightColor="#F7F7F7">
                    <Row className="d-flex justify-content-between mb-5">
                        <Col>
                            <Skeleton height={400} />
                        </Col>
                        <Col>
                            <Skeleton height={400} />
                        </Col>
                        <Col>
                            <Skeleton height={400} />
                        </Col>
                        <Col>
                            <Skeleton height={400} />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-between mb-5">
                        <Col>
                            <Skeleton height={400} />
                        </Col>
                        <Col>
                            <Skeleton height={400} />
                        </Col>
                        <Col>
                            <Skeleton height={400} />
                        </Col>
                        <Col>
                            <Skeleton height={400} />
                        </Col>
                    </Row>
                </SkeletonTheme>
            )}


            <Paginator 
                pageCount={reports?.meta?.pagination?.pageCount} 
                page={page}
                setPage={(e: any) => {
                    setPage(e);
                }}
            />
        </>
    );
};

export default Relatorio;