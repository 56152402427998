import styled from 'styled-components';
import { NavLink as NavLinkRouter } from 'react-router-dom';

export const Navigation = styled.div`
    display: block;
    @media (max-width: 1399px){
        display: none;
    }

`;

export const NavTop = styled.nav`
    background-color: #0A3466;
    width: 100%;
    > div {
        padding: 12px 164px;
        
        > div {
            margin: 0 auto;
            max-width: 1920px;
            display: flex;
            justify-content: flex-end;
        }
    }
`;

export const NavLinkTop = styled(NavLinkRouter)`
    text-decoration: none;
    color: #FFFFFF;
    margin-left: 30px;

    :hover, &.active {
        color: #FFFFFF; 
    }
`;

export const Navbar = styled.nav`
    background-color: #FFFFFF;
    width: 100%;
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 164px;
    max-width: 1920px;
    margin: 0 auto;
`;

export const Nav = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
`;

export const Link = styled(NavLinkRouter)`
    text-decoration: none;
    color: #AEAEAE;
    margin-top: 10px;
    margin-right: 0;
    padding: 0 8px 10px 8px;
    
    :hover, &.active {
        color: #2C3941;
        
        font-weight: 700;
    }
`;

export const Button = styled.a`
    cursor: pointer;
    text-decoration: none;
    color: #2C3941;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    background: #FF9800;
    border-radius: 8px;
    width: 168px;
    height: 46px;  
    
    :hover {
        color: #2C3941;
    }

    @media (max-width: 578px){
        width: 138px;
        height: 41px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
`;

export const RespNav = styled.nav`
    display: none;
    @media (max-width: 1399px){
        z-index: 99;
        width: 100%;
        display: block;
        position: sticky;
        background-color: #FFFFFF;
        top: 0;
        left: 0;
        width: 100%;
        padding: 24px 24px 24px 12px !important;
    }

    @media (max-width: 578px){
        padding: 24px 24px 24px 12px !important;
    }
`;

export const MenuNav = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        > button {
            background: none;
            border: none;
            width: 32px;
            height: 32px;
            margin-left: 40px;
    
            > img {
                width: 28px;
                height: 28px;
            }
        }
    }

    @media (max-width: 578px){
        > div {
            > button {
                margin-left: 10px;
            }
        }
    }

`;

export const LinkMenuResp = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-left: 20px;
    background: #FFFFFF;
    width: 100%;
`;

export const LinkNavResp = styled(NavLinkRouter)`
    text-decoration: none;
    color: #AEAEAE;
    margin-bottom: 10px;
    
    :hover, &.active {
        color: #2C3941;
        font-weight: 700;
    }
`;