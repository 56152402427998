import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { api } from "../Functions";
import { IAbout } from "../Interfaces/IAbout";

const getAbout = async <T>():Promise<IAbout> => {
    const resp = await api.get<IAbout>(`/about-uses?populate=*&sort=id%3AASC`);

    return resp.data;
};

export const useAbout = <T>():UseQueryResult<IAbout> => {
    return useQuery(['about'], () => getAbout());
};