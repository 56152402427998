import React, { useEffect, useState } from 'react';
import * as S from './style';
import { 
    BarChart, 
    Bar, 
    Cell, 
    XAxis, 
    Tooltip, 
    ResponsiveContainer,
} from 'recharts';
import { Col, ProgressBar, Row } from 'react-bootstrap';

type List = {
    month: string,
    total: number
}

interface IProps {
    title: string | undefined,
    number: number,
    data?: any[],
    width?: string,
    height?: string,
    heightGrafic?: number,
    services: any
}

const YearGrafic: React.FC <IProps> = (props) => {
    const [focusBar, setFocusBar] = useState<any>(null);
    const [mouseLeave, setMouseLeave] = useState<any>(true);
    const [ list, setList ] = useState<any>([]);
    const [ data, setdata ] = useState([])

    useEffect(() => {
        let aux: any = []
        props.data?.forEach((id:any) => {
            aux.push({
                ...id,
                month: id?.month?.charAt(0) + id?.month?.charAt(1) + id?.month?.charAt(2) 
            })
        })

        setdata(aux)
    }, [])


    return (
        <div>
            <S.Container>
                <div>
                    <h1>{`${props.title} `} </h1>
                    <p>{props.number}</p>
                </div>
                <Row>
                    <Col lg={12}>
                        <ResponsiveContainer width="100%" height={props.heightGrafic}>
                            <BarChart                            
                                height={props.heightGrafic}
                                data={data}
                                onMouseMove={(state) => {
                                    if (state.isTooltipActive) {
                                        setFocusBar(state.activeTooltipIndex);
                                        setMouseLeave(false);
                                    } else {
                                        setFocusBar(null);
                                        setMouseLeave(true);
                                    }
                                }}
                                barSize={20}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <Tooltip cursor={false} />
                                <XAxis dataKey="month" scale="point" />
                                <Bar 
                                    radius={[25, 25, 0, 0]} 
                                    dataKey="value" 
                                    fill="#C7C7C7" 
                                    width={80}
                                >
                                    {data?.map((entry: any, index: any) => (
                                        <Cell 
                                            fill={focusBar === index ? "#2B5CE7" : "#C7C7C7"} 
                                            style={{border: '1px solid pink !important'}}
                                            onMouseMove={(focus) => {
                                                if(focusBar === index){
                                                    setList(entry)
                                                }
                                            }}
                                        />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col lg={6} >
                        <Row className='d-flex flex-column justify-content-between flex-lg-row mt-4'>
                            {props?.services?.map((id: any, index: number) => (
                                <Col sm={12} lg={12} key={index} className='mb-3'>
                                    <Col className='d-flex justify-content-between'>
                                        <p style={{margin: '0'}}>{id?.name}</p>
                                        <b>{id?.total}</b>
                                    </Col>
                                    <ProgressBar variant={id?.name === 'Água' ? 'info' : 'warning'} now={id?.total} />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </S.Container>
        </div>
    );
};

export default YearGrafic;