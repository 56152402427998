import styled from "styled-components";

export const Container = styled.div`
    background-color: #FFFFFF !important;
`;

export const Card = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 12px rgba(44, 44, 44, 0.1);
    border-radius: 8px;

    > header {  
        border-radius: 8px 8px 0px 0px;
        background: #2C394140;
        padding: 20px 32px;
        height: 60px;
        
        > h1{
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #0A3466;
            margin: 0 !important;
        }

    }

    > div {
        padding: 32px;
    }

    label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #2C3941;
        margin-bottom: 5px;
    }

`;

export const Button = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    width: 255px;
    height: 60px;
    background: #1773E2;
    border: 1px solid #1773E2;
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
`;

export const Options = styled.p`
    margin: 0 0 0 -9px !important;
    padding: 0 !important;
`;

export const ResultSearch = styled.div`
    margin-top: 40px;

    > header {
        display: flex;
        justify-content: space-between;

        > h1 {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #2C3941;
        }

    }
`;

export const Download = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    background:#2261AC;
    border:none;
    color: #FFFFFF;
    font-weight: 700;
    border-radius: 10px; 
    text-decoration: none;
    position: relative;
    cursor: pointer;

    :hover {
        color: #FFFFFF;
    }

    > img {
        width: 32px;
        margin-left: 28px;
    }
`;

export const ContainerMap = styled.div`
    width: 100%;
    height: 510px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
    border-radius: 20px;
    
    > img {
        object-fit: cover !important;
        width: 100%;
        height: 510px;
    }
`;

export const ContainerCard = styled.div`
    width: 100%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 32px;
`;


export const DivRender = styled.div`
    @media print {
        @page { size: landscape !important; }
    }
`