import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { api } from "../Functions";
import { IPartners } from "../Interfaces/IPartners";

const getPartners = async <T>():Promise<IPartners> => {
    const resp = await api.get<IPartners>(`/partners?populate=*,partners1.image,partners2.image,partners3.image`);

    return resp.data;
};

export const usePartners = <T>():UseQueryResult<IPartners> => {
    return useQuery(['partners'], () => getPartners());
};