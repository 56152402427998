import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap'; 
import { Relatorio, ProduzaSeusDados } from '../index';
import { Helmet } from "react-helmet";
import * as S from './style';

const Dados: React.FC = () => {
    const [ show, setShow ] = useState('show');
    const [ close, setClose ] = useState('');

    return (
        <S.Content>
            <Helmet 
                title="Sos - Água e Luz | Dados"
            />
            <div>
                <Row className="mb-5">
                    <Col xl={2}>
                        <S.Button
                            value={show}
                            type='button'
                            onClick={() => {
                                setShow('show')
                                setClose('')
                            }}
                        >
                            Produza seus dados
                        </S.Button>
                    </Col>
                    <Col xl={2}>
                        <S.Button
                            value={close}
                            type='button'
                            onClick={() => {
                                setShow('')
                                setClose('show')
                            }}
                        >
                            Relatórios
                        </S.Button>
                    </Col>
                </Row>
                {show === 'show' && (
                    <ProduzaSeusDados />
                )}
                {close === 'show' && (
                    <Relatorio />
                )}
            </div>
        </S.Content>
    );
};

export default Dados;