import styled from 'styled-components';
import {
    Row as RowBt,
    Card as CardBt
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { mapBg } from '../../Assets/index';

export const Container = styled.div`
    width: 100%;
    > div {
        margin: 0 auto;
        max-width: 1920px;
        overflow-x: hidden;
        > header {
            position: relative;
            height: 80vh;
        }
    
        @media (max-width: 576px){
            > header {
                height: 80vh;
            }
        }
    
        @media (min-width: 576px) and (max-width: 767px) {  
            > header {
                height: 80vh;
            }
        }
    
        @media (min-width: 768px) and (max-width: 991px) { 
            > header {
                height: 80vh;
            }
        }
    
        @media (min-width: 992px) and (max-width: 1199px) {  
            > header {
                height: 80vh;
            }
        }
    
        @media (min-width: 1200px) and (max-width: 1399px) {  
            > header {
                height: 80vh;
            }
        }
    
        @media (min-width: 1400px){       
            > header {
                height: 80vh;
            }
        }
    }
`;

export const NumbersContent = styled.div`
    width: 100%;
    background: #F8F8F8;

    > div {
        max-width: 1920px;
        margin: 0 auto;
    }
`;

export const SectionNews = styled.div`
    width: 100%;
    background: #0A3466;
    color: #FFFFFF;

    > div {
        margin: 0 auto;
        max-width: 1920px;
    }
`;

export const SectionAbout = styled.div`
    width: 100%;
    background: #F8F8F8;
    > div {
        margin: 0 auto;
        max-width: 1920px;
    }
`;

export const SectionImg = styled.div`
    width: 100%;
    background: #FFFFFF;
    > div {
        max-width: 1920px;
        margin: 0 auto;
    }
`;

export const DownloadNow = styled.div`
    width: 100%;
    background: #0A3466;
    color: #FFFFFF;

    > div {
        margin: 0 auto;
        max-width: 1920px;
    }

`;

export const LastPost = styled.div`
    width: 100%;
    background: #FFFFFF;
    > div {
        max-width: 1920px;
        margin: 0 auto;
    }
`;

export const MapImg = styled.img`
    width: 1920px;
    position: fixed;
    object-fit: cover;
    z-index: -99;
    top: 0;
    left: 0;

    @media (max-width: 576px){
        width: 100vw;
        height: 800px;
        object-fit: cover;
    }

    @media (min-width: 576px) and (max-width: 767px) {  
        width: 100vw;
        height: 800px;
        object-fit: cover;
    }

    @media (min-width: 768px) and (max-width: 991px) { 
        width: 100vw;
        height: 800px;
        object-fit: cover;
    }

    @media (min-width: 992px) and (max-width: 1199px) {  
        width: 100vw;
        height: 800px;
        object-fit: cover;
    }

    @media (min-width: 1200px) and (max-width: 1399px) {  
        width: 100vw;
        height: 800px;
        object-fit: cover;
    }

    @media (min-width: 1400px){       
        width: 100vw;
        height: 800px;
        object-fit: cover;
    }

    @media (min-width: 1920px){
        max-width: 1920px;
        height: 1150px;
        object-fit: cover;
        left: auto;
    }
`;

export const Triangle = styled.div`
    width: 100%; 
    height: 40px; 
    border-left: 960px solid transparent;
    border-right: 960px solid transparent;
    border-bottom: 50px solid #FFFFFF;
    z-index: 999 !important;

    > a {
        cursor: pointer;
    }

    @media (max-width: 576px){
        border-left: 190px solid transparent;
        border-right: 190px solid transparent;
        border-bottom: 50px solid #FFFFFF;
        
        > a {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 10px;
        }
    }

    @media (min-width: 576px) and (max-width: 767px) {  
        border-left: 290px solid transparent;
        border-right: 290px solid transparent;
        border-bottom: 50px solid #FFFFFF;
        
        > a {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 10px;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) { 
        border-left: 390px solid transparent;
        border-right: 390px solid transparent;
        border-bottom: 50px solid #FFFFFF;
        
        > a {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 10px;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {  
        border-left: 480px solid transparent;
        border-right: 480px solid transparent;
    }

    @media (min-width: 1200px) and (max-width: 1399px) {  
        border-left: 650px solid transparent;
        border-right: 650px solid transparent;
       
    }

    @media (min-width: 1400px){     
        border-left: 800px solid transparent;
        border-right: 800px solid transparent;  
    }

    @media (min-width: 1920px){
        border-left: 950px solid transparent;
        border-right: 950px solid transparent;  
    }
`;

export const Section = styled.section`
    background: #FFFFFF;

    padding-top: 100px;
    text-align: center;

    > h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        text-transform: uppercase;
        color: #FF9800;
        margin-bottom: 20px;
    }

    > h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: #2C3941;
        margin-bottom: 40px;
    }

    > p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #2C3941;
        margin-bottom: 112px;
    }

    @media (max-width: 576px){
        padding: 40px 16px;

    }

    @media (min-width: 577px) and (max-width: 767px) {  
        padding: 40px 32px;
    }

    @media (min-width: 768px) and (max-width: 991px) { 
        padding: 40px 32px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {  
        padding: 40px 150px;
    }

    @media (min-width: 1200px) and (max-width: 1399px) {  
       
    }

    @media (min-width: 1400px){       
        
    }
`;

export const Card = styled(CardBt)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px 32px;
    width: 100%;
    height: 296px;
    background: #FFFFFF;
    box-shadow: 0px 20px 50px rgba(44, 44, 44, 0.2);
    border: none;
    border-radius: 20px;

    > img {
        width: 60px;
    }

    > h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: #2C3941;
        text-align: left;
    }

    > p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #2C3941;
        text-align: left;
    }

    @media (max-width: 576px){
        margin-bottom: 20px;
        max-width: 300px;
    }

    @media (min-width: 577px) and (max-width: 767px) {  
        
    }

    @media (min-width: 768px) and (max-width: 991px) { 
        
    }

    @media (min-width: 992px) and (max-width: 1199px) {  
        
    }

    @media (min-width: 1200px) and (max-width: 1399px) {  
       
    }

    @media (min-width: 1400px){       
        
    }
`;

export const OurDate = styled.div`
    padding: 150px 55px;
    background: #F8F8F8;

    > h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        text-transform: uppercase;
        color: #1773E2;
        text-align: center;
    }

    > h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        color: #2C3941;
    }

    > p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #2C3941;
    }

    @media (min-width: 576px) and (max-width: 767px) {  
        
    }

    @media (min-width: 768px) and (max-width: 991px) { 
        
    }

    @media (min-width: 992px) and (max-width: 1199px) {  
        
    }

    @media (min-width: 1200px) and (max-width: 1399px) {  
       
    }

    @media (min-width: 1400px){       
        
    }
`;

export const Description = styled.p`
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #2C3941;
    opacity: 0.5;
`;

export const News = styled.div`
    padding-top: 160px;
    > h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        text-transform: uppercase;
        color: #1773E2;
        margin-bottom: 12px;
    }

    > h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: #2C3941;
        margin-bottom: 12px;
    }

    > p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #2C3941;
        align-self: stretch;
    }
`;

export const LearnMore = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 255px;
    height: 60px;
    background: #2261AC;
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    text-decoration: none;

    :hover {
        color: #FFFFFF;
    }

    > img {
        width: 32px;
    }
`;

export const MockAppSection = styled.div`
    background: url(${mapBg});
    height: 619px;
    display: flex;
    align-items: center;
    justify-content: center;

    .imgOne {
        width: 900px;
        margin-left: 300px;
        margin-bottom: -300px;
        animation: float 3s ease-in-out infinite;

        @keyframes float {
            0% {
                transform: translateY(0px);
            }

            50% {
                transform: translateY(-30px);
            }

            100% {
                transform: translateY(0px);
            }
        }
    }

    .imgTwo {
        width: 509px;
        animation: float 5s ease-in-out infinite;

        @keyframes float {
            0% {
                transform: translateY(0px);
            }

            50% {
                transform: translateY(-30px);
            }

            100% {
                transform: translateY(0px);
            }
        }
    }

    .imgThree {
        width: 509px;
        animation: float 2s ease-in-out infinite;

        @keyframes float {
            0% {
                transform: translateY(0px);
            }

            50% {
                transform: translateY(-30px);
            }

            100% {
                transform: translateY(0px);
            }
        }
    }

    @media (max-width: 576px){
        flex-direction: column-reverse;
        .imgOne {
            width: 550px;
            margin-bottom: -80px;
            margin-left: 50px;
            margin-top: -50px;
            animation: float 5s ease-in-out infinite;
        }

        .imgTwo {
            width: 450px;
            margin-left: -180px;
            animation: float 3s ease-in-out infinite;
        }

        .imgThree {
            width: 450px;
            margin-top: -180px;
            margin-right: -180px;
        }

        > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
        }
    }

    @media (min-width: 577px) and (max-width: 767px) {  
        flex-direction: column-reverse;
        .imgOne {
            width: 550px;
            margin-bottom: -80px;
            margin-left: 50px;
            margin-top: -50px;
            animation: float 5s ease-in-out infinite;
        }

        .imgTwo {
            width: 450px;
            margin-left: -180px;
            animation: float 3s ease-in-out infinite;
        }

        .imgThree {
            width: 450px;
            margin-top: -180px;
            margin-right: -180px;
        }

        > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) { 
        flex-direction: row;
        .imgOne {
            width: 550px;
            margin-bottom: -80px;
            margin-left: -150px;
            margin-top: -50px;
            animation: float 5s ease-in-out infinite;
        }

        .imgTwo {
            width: 450px;
            margin-left: -180px;
            animation: float 3s ease-in-out infinite;
        }

        .imgThree {
            width: 450px;
            margin-top: -150px;
            margin-right: -180px;
        }

        > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {  
        flex-direction: row;
        .imgOne {
            width: 850px;
            margin-bottom: -120px;
            margin-left: -250px;
            margin-top: 0;
            animation: float 5s ease-in-out infinite;
        }

        .imgTwo {
            width: 450px;
            margin-left: -200px;
            animation: float 3s ease-in-out infinite;
        }

        .imgThree {
            width: 450px;
            margin-top: -150px;
            margin-right: -180px;
        }

        > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1399px) {  
        flex-direction: row;
        .imgOne {
            width: 850px;
            margin-bottom: -240px;
            margin-left: -250px;
            margin-top: 0;
            animation: float 5s ease-in-out infinite;
        }

        .imgTwo {
            width: 450px;
            margin-left: -200px;
            animation: float 3s ease-in-out infinite;
        }

        .imgThree {
            width: 450px;
            margin-top: -150px;
            margin-right: -180px;
        }

        > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
        }
    }

    @media (min-width: 1400px){       
        flex-direction: row;
        .imgOne {
        width: 900px;
        margin-left: 300px;
        margin-bottom: -300px;
        animation: float 3s ease-in-out infinite;

        @keyframes float {
            0% {
                transform: translateY(0px);
            }

            50% {
                transform: translateY(-30px);
            }

            100% {
                transform: translateY(0px);
            }
        }
    }

    .imgTwo {
        width: 509px;
        animation: float 5s ease-in-out infinite;

        @keyframes float {
            0% {
                transform: translateY(0px);
            }

            50% {
                transform: translateY(-30px);
            }

            100% {
                transform: translateY(0px);
            }
        }
    }

    .imgThree {
        width: 509px;
        animation: float 2s ease-in-out infinite;

        @keyframes float {
            0% {
                transform: translateY(0px);
            }

            50% {
                transform: translateY(-30px);
            }

            100% {
                transform: translateY(0px);
            }
        }
    }

        > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
        }
    }
`;

export const DownloadApp = styled.a`
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 16px 20px;
    width: 100%;
    background: #FFFFFF;
    color: #0A3466;
    font-weight: 700;
    border-radius: 10px;
`;

export const SectionDownload = styled(RowBt)`
    padding: 150px 16px 50px 16px;
    width: 100%;
    background: #0A3466; 
    color: #FFFFFF;

    > h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: #FFFFFF;
    }

    > p {
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
    }

    @media (min-width: 577px) and (max-width: 767px) {  
        
    }

    @media (min-width: 768px) and (max-width: 991px) { 
        
    }

    @media (min-width: 992px) and (max-width: 1199px) {  
        
    }

    @media (min-width: 1200px) and (max-width: 1399px) {  
       
    }

    @media (min-width: 1400px){       
        
    }
`;

export const ShowMap = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background: #2261AC;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    text-decoration: none;
    padding: 0 24px;
    border: 0;

    :hover {
        color: #FFFFFF;
    }

    > img {
        width: 32px;
    }
`;

export const LastPosts = styled.div`
    background: #FFFFFF;
    padding: 32px 16px;

    > h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        color: #2C3941;
        margin-bottom: 40px;
    }
`;