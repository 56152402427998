import React from 'react';
import { useQuestions } from '../../Hooks';
import { 
    Col,
    Container,
    Row
} from 'react-bootstrap';
import { Accordion } from '../../Components';
import { Helmet } from "react-helmet";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const PerguntasFrequentes: React.FC = () => {
    const { data: questions, isLoading } = useQuestions();

    console.log(isLoading);
    
    return (
        <Container>
            <Helmet 
                title="Sos - Água e Luz | Perguntas Frequentes"
            />
            <Row>
                <h1 className="fs-1 mb-5">Perguntas Frequentes</h1>
            </Row>
            {!isLoading ? (
                <Row className="mb-5">
                    {questions?.data?.map((id: any, index: number) => (
                        <Accordion
                            title={id?.attributes?.title}
                            content={id?.attributes?.description}
                            eventKey={index}
                        />
                    ))}
                </Row>
            ) : (
                <SkeletonTheme baseColor="#F1F1F1" highlightColor="#F7F7F7">
                    <Row className="d-flex justify-content-between">
                        <Skeleton  count={6} height={60} />
                    </Row>
                </SkeletonTheme>
            )}
        </Container>
    );
};

export default PerguntasFrequentes;