import styled from 'styled-components';

export const Content = styled.div`
    background: #FFFFFF;
    width: 100%;

    > div {
        @media (max-width: 576px) {
            padding: 20px 16px;
        }
    
        @media (min-width: 577px) and (max-width: 767px) {  
            padding: 20px 32px;
        }
    
        @media (min-width: 768px) and (max-width: 991px) { 
            padding: 20px 32px;
        }
    
        @media (min-width: 992px) and (max-width: 1199px) {  
            padding: 20px 32px;
        }
    
        @media (min-width: 1200px) and (max-width: 1399px) {  
            padding: 20px 32px;
        }
    
        @media (min-width: 1400px){    
            padding: 20px 180px;
            max-width: 1920px;
            margin: 0 auto;
        }
    }

`;

export const Button = styled.button<{ value: string }>`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2C3941;
    border: none;
    background: none;
    height: 56px;

    ${props => {
        if(props.value !== ''){
            return `
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #2C3941;
                border-bottom: 3px solid #1773E2;
            `;
        }
    }}
`;