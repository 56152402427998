import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { api } from "../Functions";
import { IBlog, IBlogById } from "../Interfaces/IBlog";

const getBlog = async <T>(
    page: number,
    title?: string
):Promise<IBlog []> => {

    let params = new URLSearchParams();

    if(title != undefined){
        params.append("filters[title][$contains]", title)
    }

    params.append('pagination[page]', page.toString());
    params.append('pagination[pageSize]', '15');
    params.append('populate', '*');
    params.append('sort', 'id:desc');

    const resp = await api.get<IBlog[]>(`/blogs`, {
        params: params
    });

    return resp.data;
};

export const useBlog = <T>(page: number, title?: string):UseQueryResult<IBlog> => {
    return useQuery(['blog', page, title], () => getBlog(page, title));
};

const getBlogById = async <T>(slug: string):Promise<IBlog []> => {
    const resp = await api.get<IBlog []>(`/blogs?populate=*&filters%5Bslug%5D=${slug}`);

    return resp.data;
};

export const useBlogById = <T>(slug: any):UseQueryResult<IBlog> => {
    return useQuery(['blog_id'], () => getBlogById(slug));
};