import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

export const Container = styled(Col)`
    h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #AEAEAE;
    }

    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #2C3941;
    }
`;

export const Values = styled.section`
    width: 283.97px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
        
    > div {
        display: flex;
        flex-direction: column;
        align-items: left;
        margin-left: 12px;
        margin-right: 20px;
        margin-bottom: 20px;
        > div {
            border-radius: 5px;
            width: 45px;
            height: 8px;
            margin-right: 2px;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #6E6B7B;
            margin: 0 0 5px 0 !important;
        }
    }
`;