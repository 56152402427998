import React from 'react';
import * as S from './style';
//import { FaleConosco, CadastreSe } from '../index';
import { Col, Row } from 'react-bootstrap';
import {
    emailIcon,
    whatsAppIcon,
    facebookIcon,
    instagramIcon,
    linkedinIcon,
    arowIcon,
    logo,
    logoSVG
} from '../../Assets/index'
import { useFooter } from '../../Hooks/useFooter';
import { SocialMedia } from '../../Interfaces/IFooter';

const Footer: React.FC = () => {

    const { data } = useFooter();

    function rollTop(){
        window.scroll({
            top: 0,
            behavior: 'smooth'
        })
    };

    console.log(data?.data[0]?.attributes?.social_medias[0]?.social_media?.data[0]?.attributes?.url, 'hi hi hi hi')

    return (
        <S.Container>
            <S.Div>
                {/*<Row className="d-flex justify-content-between">
                    <Col xs={12} lg={6}>
                        <FaleConosco />
                    </Col>
                    <Col xs={12} lg={4}>
                        <CadastreSe />
                    </Col>
                </Row>*/}
                <S.Contact>
                    <div>
                        <img src={whatsAppIcon} alt="Whats app icon" />
                        <p><u>{data?.data[0]?.attributes?.phone}</u></p>
                    </div>
                    <div>
                        <img src={emailIcon} alt="Whats app icon" />
                        <p><u>{data?.data[0]?.attributes?.email}</u></p>
                    </div>
                </S.Contact>
                <Row>
                    <hr />
                </Row>
                <Row className="d-lg-flex">
                    <Col md={4} lg={4}>
                        <S.ContentLogo>
                            <img src={logoSVG} alt="SOS - Logo" />
                        </S.ContentLogo>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                        <Row>
                            <h2>Redes Sociais</h2>
                        </Row>
                        <Row className="mb-3">
                            {data?.data[0]?.attributes?.social_medias?.map((id: SocialMedia, index: number) => (
                                <Col xxs={1} xs={1} sm={1} md={2} key={index}>
                                    <a href={id?.link} target="_blank">
                                        <img src={id?.social_media?.data[0]?.attributes?.url} alt="linkedin icon" />
                                    </a>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                        <Row>
                            <h2>Informativo</h2>
                        </Row>
                        <Row className="mb-3">
                            <S.Link to="/perguntas-frequentes">Perguntas frequentes</S.Link>
                        </Row>
                        <Row>
                            <S.Link to="/politica-de-privacidade">Políticas de privacidade</S.Link>
                        </Row>
                    </Col>
                </Row>
                <hr />
                <Row className="d-flex justify-content-between justify-content-xl-between">
                    <Col xs={8} xl={11} className="mt-2 text-light" >
                        <p>Todos os direitos reservados.</p>
                    </Col>
                    <Col xs={4} xl={1}>
                        <S.Button 
                            type='button'
                            onClick={() => {
                                rollTop()
                            }}
                        >
                            TOPO
                            <img src={arowIcon} alt="Arow icon" />
                        </S.Button>
                    </Col>
                </Row>
            </S.Div>
        </S.Container>
    );
};

export default Footer;